import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d86ff3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contract-popup" }
const _hoisted_2 = { class: "contract-message mt-5" }
const _hoisted_3 = { class: "message" }
const _hoisted_4 = { class: "message" }
const _hoisted_5 = { class: "access-input mt-5" }
const _hoisted_6 = { class: "confirm-button sign-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.$t('sign.accessTitleText'),
        onOnClickOutsidePopup: _ctx.hide,
        onOnHide: _ctx.hide,
        class: "contract-popup"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", null, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("sign.accessFirstInfoLine")), 1),
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("sign.accessSecondInfoLine")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_ValidationTextField, {
                rules: "required",
                name: "accessCode",
                message: {
            error_required: _ctx.$t('errors.required'),
          },
                value: _ctx.code,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
                type: "text",
                placeholder: _ctx.$t('sign.popupPlaceholder')
              }, null, 8, ["message", "value", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_FlatButton, {
                onOnClick: _ctx.onInputAccessCode,
                text: _ctx.$t('sign.sign'),
                enabled: _ctx.code != ''
              }, null, 8, ["onOnClick", "text", "enabled"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}

import { defineComponent, reactive, toRefs, watch, inject } from "vue";

import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import { computed } from "vue";
import Popup from "@/components/popups/Popup.vue";
import { mapMutations, useStore } from "vuex";
import { useForm } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import {
  FolderRepository,
  ContractRepository,
  RepositoryFactory,
} from "@/lib/https";
export default defineComponent({
  name: "FolderRenamePopup",
  components: {
    ValidationTextField,
    FlatButton,
    Popup,
  },
  props: {
    renameSelectedPopup: { type: Number, required: true },
    folderItem: { type: Object },
    contractItem: { type: Object },
    isTemplateFolder: {type: Boolean, default: false }
  },
  emits: ["onSubmitRename"],
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      name: "",
      isLoading: false,
    });

    const { renameFolder } =
      RepositoryFactory.getRepository<FolderRepository>(FolderRepository);

    const { renameFile } =
      RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

    const RenamePopupList = inject("RenamePopupList") as any;

    watch(
      () => props.folderItem,
      (newVal: any) => {
        state.name = newVal.name;
      }
    );

    watch(
      () => props.contractItem,
      (newVal: any) => {
        state.name = newVal.title;
      }
    );

    const form = useForm();
    const { formHandle } = useFormHandler(form, async () => {
      await confirmEdit();
    });

    const submitEdit = async function () {
      await formHandle();
    };

    const confirmEdit = async function () {
      if (props.renameSelectedPopup === RenamePopupList.RENAME_FOLDER) {
        let payload = {
          id: props.folderItem?.id,
          name: state.name,
          isTemplateFolder: false
        };
        if(props.isTemplateFolder) {
          payload.isTemplateFolder = true;
        }
        await renameFolder(payload)
          .then(async (res) => {
            context.emit("onSubmitRename", { res });
          })
          .catch((err) => {
            context.emit("onSubmitRename", { err });
          });
      } else if (
        props.renameSelectedPopup === RenamePopupList.RENAME_CONTRACT &&
        props.contractItem?.isFile
      ) {
        let payload = {
          id: props.contractItem?.id,
          title: state.name,
        };
        await renameFile(payload)
          .then(async (res) => {
            context.emit("onSubmitRename", { res });
          })
          .catch((err) => {
            context.emit("onSubmitRename", { err });
          });
      }
      store.commit("popup/unsetPopup", "FolderRename");
    };

    return {
      ...toRefs(state),
      popupState: computed(() => store.state.popup.FolderRename),
      submitEdit,
      RenamePopupList,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),
    hidePopup(): void {
      this.unsetPopup("FolderRename");
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */


import { defineComponent, reactive, toRefs, inject, watch } from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import { formatDate } from "@/lib/utility/common";
import { mapMutations } from "vuex";
import { hasPermission, ReadWritePermissions } from "@/lib/utility/permission";

export default defineComponent({
  name: "FolderItem",
  components: {
    TextButton,
    MenuOptionDropdown,
  },
  props: {
    folder: { type: Object, required: true },
    selectedFolderList: { type: Array },
    showMenuOptionId: { type: String, default: "" },
    folderPath: { type: String, default: "" },
    statusPath: { type: String, default: "" },
  },
  setup(props) {
    const state = reactive({
      isHover: false,
      isSelected: false,
      hasPermission: hasPermission(props.folder.role),
    });

    const isMobile = inject("isMobile");

    watch(
      () => props.selectedFolderList,
      (selectedFolderList) => {
        state.isSelected =
          selectedFolderList?.some(
            (item: any) => item.id === props.folder.id
          ) ?? false;
      }
    );

    return {
      ...toRefs(state),
      isMobile,
      formatDate,
    };
  },
  methods: {
    ...mapMutations("notification", ["showErrorNotification"]),
    handleSelectFolder(item: any) {
      item.isFolder = true;
      this.isHover = false;
      this.$emit("onSelect", item, this.isSelected);
    },
    handleAccessFolder() {
      if (!hasPermission(this.folder.role)) {
        this.showErrorNotification(
          this.$t("notification.accessPermissionError")
        );
      } else {
        this.$router.push({
          name: "Folder",
          query: {
            folderId: this.folder.id,
            folderTitle: this.folder.name,
            status: this.statusPath,
          },
          params: { folderPath: this.folderPath },
        });
      }
    },
    handleClickIcon(item: any) {
      this.isSelected = true;
      this.handleSelectFolder(item);
    },
    handleShowMenuOption(id: string) {
      this.$emit("onShowMenuOption", id);
    },
  },
});

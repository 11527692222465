
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted,
  watch,
  computed,
} from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import Chip from "@/components/atomics/Chip.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import CustomDatepicker from "@/components/atomics/CustomDatepicker.vue";
import Popup from "@/components/popups/Popup.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import { RepositoryFactory, NotificationRepository } from "@/lib/https";
import { useForm } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { mapMutations, useStore } from "vuex";
import { isEmail } from "@/lib/utility/stringUtil";
export default defineComponent({
  name: "EditFolderNotificationPopup",
  props: {
    contractItem: { type: Object, required: true },
    title: { type: String, required: true },
    time: { type: String, default: "" },
    hours: { type: String, default: "" },
  },
  components: {
    ValidationTextField,
    Chip,
    CustomDatepicker,
    Dropdown,
    FlatButton,
    TextButton,
    Popup,
    MenuOptionDropdown,
  },
  setup(props, context) {
    const { t } = useI18n();
    const store = useStore();
    const initNotificationTime = "";

    const state = reactive({
      isAdd: !props.contractItem.contractNotificationId,
      emailInput: "",
      hours: [
        { id: "0", name: "0:00 AM" },
        { id: "1", name: "1:00 AM" },
        { id: "2", name: "2:00 AM" },
        { id: "3", name: "3:00 AM" },
        { id: "4", name: "4:00 AM" },
        { id: "5", name: "5:00 AM" },
        { id: "6", name: "6:00 AM" },
        { id: "7", name: "7:00 AM" },
        { id: "8", name: "8:00 AM" },
        { id: "9", name: "9:00 AM" },
        { id: "10", name: "10:00 AM" },
        { id: "11", name: "11:00 AM" },
        { id: "12", name: "12:00 PM" },
        { id: "13", name: "1:00 PM" },
        { id: "14", name: "2:00 PM" },
        { id: "15", name: "3:00 PM" },
        { id: "16", name: "4:00 PM" },
        { id: "17", name: "5:00 PM" },
        { id: "18", name: "6:00 PM" },
        { id: "19", name: "7:00 PM" },
        { id: "20", name: "8:00 PM" },
        { id: "21", name: "9:00 PM" },
        { id: "22", name: "10:00 PM" },
        { id: "23", name: "11:00 PM" },
      ],
      contractNotification: {
        id: "",
        contractId: "",
        subject: "",
        body: "",
        notificationDate: null,
        notificationTime: initNotificationTime,
        Sent: "",
        createdAt: "",
        isErrorOfEmailTagField: "",
        receiveEmails: [] as any,
      },
    });

    const {
      createContractNotification,
      getCompanyEmailReceivers,
      getContractNotification,
      updateContractNotification,
    } = RepositoryFactory.getRepository<NotificationRepository>(
      NotificationRepository
    );

    const form = useForm();
    const { formHandle } = useFormHandler(form, async () => {
      if (state.emailInput && !isEmail(state.emailInput)) {
        state.contractNotification.isErrorOfEmailTagField =
          t("errors.formatEmail");
        return;
      }

      if (state.contractNotification.receiveEmails.length < 1) {
        state.contractNotification.isErrorOfEmailTagField =
          t("errors.required");
        return;
      }
      submitNotification();
    });

    const submitNotificationBtn = async function () {
      await formHandle();
    };

    const showSuccessNotification = (text: string) => {
      store.commit("notification/showSuccessNotification", text);
    };
    const showErrorNotification = (text: string) => {
      store.commit("notification/showErrorNotification", text);
    };
    const showPopupErrorNotification = (text: string) => {
      store.commit("notification/showPopupErrorNotification", text);
    };

    const submitNotification = () => {
      let notificationDate = null;
      if (state.contractNotification.notificationDate) {
        notificationDate = new Date(
          state.contractNotification.notificationDate as any
        );
        notificationDate.setHours(
          parseInt(state.contractNotification.notificationTime)
        );
        notificationDate = moment(notificationDate).format();
      }

      const data = {
        contractId: props.contractItem.id,
        subject: state.contractNotification.subject,
        body: state.contractNotification.body,
        emails: state.contractNotification.receiveEmails,
        notificationDate: moment(notificationDate).format(),
        id: state.isAdd ? null : state.contractNotification.id,
      };
      if (state.isAdd) {
        submitForm(data);
      } else {
        editForm(data);
      }
    };

    const submitForm = async (data: any) => {
      try {
        const response = await createContractNotification(data);

        if (response) {
          context.emit(
            "onSubmitNotification",
            props.contractItem.id,
            response.data.id
          );
          showSuccessNotification(t("notification.createAlertNotifications"));
          resetPopup();
        }
      } catch (err: any) {
        if (err.data) showPopupErrorNotification(err.data.message);
      }
    };

    const editForm = async (data: any) => {
      try {
        const response = await updateContractNotification(data);
        if (response) {
          context.emit(
            "onSubmitNotification",
            props.contractItem.id,
            response.data.id
          );
          showSuccessNotification(t("notification.updateAlertNotifications"));
          resetPopup();
        }
      } catch (err: any) {
        if (err.data) showPopupErrorNotification(err.data.message);
      }
    };

    const onDeleteNotification = async (notificationID: string) => {
      store.commit("notification/removeAllNotification");
      if (notificationID) {
        context.emit("onDeleteNotification", notificationID);
      }
    };

    const scrollToBottom = () => {
      let item = document.querySelector(".popup-container");
      item?.scrollTo({
        top: item.scrollHeight,
        behavior: "smooth",
      });
    };

    onMounted(() => {
      let item = document.querySelector(".email-area");
      item?.scrollTo({
        top: item.scrollHeight,
        behavior: "smooth",
      });
    });

    watch(
      () => props.contractItem,
      async (newVal) => {
        if (!newVal.id) {
          resetPopup();
          return;
        }
        state.isAdd = !newVal.contractNotificationId;
        if (state.isAdd) {
          const response = await getCompanyEmailReceivers();
          if (response) {
            const emails = response.data || [];
            state.contractNotification.receiveEmails = emails.map(
              (email: any) => email.email
            );
          }
        } else {
          const response = await getContractNotification(
            newVal.contractNotificationId
          );
          if (response) {
            state.contractNotification.id = response.id;
            state.contractNotification.subject = response.subject;
            state.contractNotification.body = response.body;
            state.contractNotification.contractId = response.contractId;
            state.contractNotification.receiveEmails = response.emails;
            const notificationDate = new Date(response.notificationDate) as any;
            const notificationTime = new Date(response.notificationTime) as any;
            state.contractNotification.notificationDate = notificationDate;
            state.contractNotification.notificationTime = notificationDate
              .getHours()
              .toString();
          }
        }
      }
    );

    const resetPopup = () => {
      state.contractNotification = {
        id: "",
        contractId: "",
        subject: "",
        body: "",
        notificationDate: null,
        notificationTime: initNotificationTime,
        Sent: "",
        createdAt: "",
        isErrorOfEmailTagField: "",
        receiveEmails: [] as any,
      };
      state.isAdd = true;
      initLoadNotificationDate.value = false;
    };

    const getFormatDate = (dateArr: Date[]) => {
      return dateArr.map((date) => moment(date).format("YYYY/MM/DD"));
    };

    const filterHour = computed(() => {
      let filterHourValue;
      if (state.contractNotification.notificationDate) {
        const notificationDate = new Date(
          state.contractNotification.notificationDate
        );
        const nowDate = new Date(Date.now());
        let [notificationDateFormat, nowDateFormat] = getFormatDate([
          notificationDate,
          nowDate,
        ]);

        if (notificationDateFormat === nowDateFormat) {
          filterHourValue = state.hours.map((hour) => ({
            ...hour,
            disabled: nowDate.getHours() >= Number(hour.id),
          }));
        }
      }
      return filterHourValue ? filterHourValue : state.hours;
    });

    const initLoadNotificationDate = ref(false);
    watch(
      () => state.contractNotification.notificationDate,
      (value, oldValue) => {
        if (value && oldValue) {
          const notificationDate = new Date(value);
          const nowDate = new Date(Date.now());
          let [notificationDateFormat, nowDateFormat] = getFormatDate([
            notificationDate,
            nowDate,
          ]);
          if (
            (state.isAdd || (!state.isAdd && initLoadNotificationDate.value)) &&
            notificationDateFormat === nowDateFormat
          ) {
            state.contractNotification.notificationTime = (
              new Date(Date.now()).getHours() + 1
            ).toString();
          }
          initLoadNotificationDate.value = true;
        }
      }
    );

    return {
      ...toRefs(state),
      filterHour,
      popupState: computed(() => store.state.popup.EditFolderNotification),
      submitNotificationBtn,
      onDeleteNotification,
      scrollToBottom,
      resetPopup,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    hidePopup() {
      this.resetPopup();
      this.unsetPopup("EditFolderNotification");
    },

    removeEmail(email: string): void {
      const index = this.contractNotification.receiveEmails.indexOf(
        email as never
      );
      this.contractNotification.receiveEmails.splice(index, 1);
    },

    validateAndAddToNotificationEmails() {
      if (isEmail(this.emailInput)) this.addEmail();
      else if (this.emailInput.trim() != "")
        this.contractNotification.isErrorOfEmailTagField =
          this.$t("errors.formatEmail");
      else this.contractNotification.isErrorOfEmailTagField = "";
    },

    addEmail() {
      if (this.emailInput.trim().length > 0) {
        if (
          !this.contractNotification.receiveEmails.includes(
            this.emailInput as string
          ) &&
          isEmail(this.emailInput)
        ) {
          this.contractNotification.receiveEmails.push(
            this.emailInput as string
          );
          this.emailInput = "";
          this.contractNotification.isErrorOfEmailTagField = "";
        }
      }
    },
  },
});

/**
 * @vuese
 * @group Layout
 * Popup layout
 */

import { vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6da7f38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-left-side" }
const _hoisted_2 = { class: "content-left-wrapper" }
const _hoisted_3 = { class: "contract-icon mr-2" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "contract-info" }
const _hoisted_7 = { class: "last-change-date" }
const _hoisted_8 = { class: "mr-1" }
const _hoisted_9 = { class: "content-right-side" }
const _hoisted_10 = {
  key: 0,
  class: "mr-1 contract-btn-sign__wrapper"
}
const _hoisted_11 = {
  key: 1,
  class: "mr-1"
}
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "option-menu-wrapper" }
const _hoisted_14 = { class: "menu-options" }
const _hoisted_15 = { class: "option" }
const _hoisted_16 = { class: "option" }
const _hoisted_17 = { class: "option" }
const _hoisted_18 = { class: "option" }
const _hoisted_19 = { class: "option" }
const _hoisted_20 = { class: "option delete" }
const _hoisted_21 = {
  key: 0,
  class: "option option-sign"
}
const _hoisted_22 = { class: "option-contract-btn-sign__wrapper w-full" }
const _hoisted_23 = { class: "option" }
const _hoisted_24 = { class: "option" }
const _hoisted_25 = { class: "option" }
const _hoisted_26 = { class: "option" }
const _hoisted_27 = { class: "option" }
const _hoisted_28 = { class: "option" }
const _hoisted_29 = { class: "option" }
const _hoisted_30 = { class: "option" }
const _hoisted_31 = { class: "option" }
const _hoisted_32 = { class: "option" }
const _hoisted_33 = { class: "option delete" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Popper = _resolveComponent("Vue3Popper")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_XIDButton = _resolveComponent("XIDButton")!
  const _component_text_button = _resolveComponent("text-button")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_menu_option_dropdown = _resolveComponent("menu-option-dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container-contract-item", {
      'bg-grey': (_ctx.showMenuOptionId == _ctx.contract.id || _ctx.isHover) && !_ctx.isSelected,
      'bg-checked': _ctx.isSelected,
    }]),
    onMouseleave: _cache[32] || (_cache[32] = ($event: any) => (_ctx.isHover = false)),
    onMouseover: _cache[33] || (_cache[33] = ($event: any) => (_ctx.isHover = true))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          ((_ctx.isHover && !_ctx.isMobile) || _ctx.isSelected)
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 0,
                type: "checkbox",
                class: "input-checkbox",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSelected) = $event)),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSelectContract(_ctx.contract)))
              }, null, 544)), [
                [_vModelCheckbox, _ctx.isSelected]
              ])
            : (_ctx.contract.isFile)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: require('icons/report_grey.svg'),
                  alt: "file",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClickIcon(_ctx.contract)))
                }, null, 8, _hoisted_4))
              : (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  src: require('icons/report_orange.svg'),
                  alt: "contract",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleClickIcon(_ctx.contract)))
                }, null, 8, _hoisted_5))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "contract-title-info",
            onMouseover: _cache[5] || (_cache[5] = ($event: any) => (_ctx.tooltipIndex = _ctx.index)),
            onMouseleave: _cache[6] || (_cache[6] = ($event: any) => (_ctx.tooltipIndex = -1))
          }, [
            _createVNode(_component_Vue3Popper, {
              content: _ctx.contract.title,
              show: _ctx.tooltipIndex == _ctx.index,
              isContract: true
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  class: "contract-title long-name mr-1",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clickTitle(_ctx.contract)))
                }, _toDisplayString(_ctx.contract.title), 1)
              ]),
              _: 1
            }, 8, ["content", "show"])
          ], 32),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.contract.updatedAt
                  ? _ctx.$t("folder.lastUpdate") + ` ${_ctx.formatDate(_ctx.contract.updatedAt, "YYYY/MM/DD")}`
                  : ""), 1),
            (!_ctx.contract.isFile)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["contract-status", _ctx.get_status_class])
                }, _toDisplayString(_ctx.contract.statusName), 3))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.isSigner)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (!_ctx.contract.needToSignByXID)
              ? (_openBlock(), _createBlock(_component_FlatButton, {
                  key: 0,
                  text: _ctx.$t('folder.sign'),
                  icon: '',
                  class: "contract-btn-sign",
                  onOnClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('onSign', _ctx.contract.id)))
                }, null, 8, ["text"]))
              : (_openBlock(), _createBlock(_component_XIDButton, {
                  key: 1,
                  text: _ctx.$t('folder.sign'),
                  class: "contract-btn-sign contract-btn-xid",
                  onOnClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('onSign', _ctx.contract.id)))
                }, null, 8, ["text"]))
          ]))
        : _createCommentVNode("", true),
      (!_ctx.contract.isFile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            (_ctx.contract.contractNotificationId)
              ? (_openBlock(), _createBlock(_component_text_button, {
                  key: 0,
                  icon: require('icons/notification.svg'),
                  "disabled-icon": require('icons/notification-grey.svg'),
                  text: '',
                  class: _normalizeClass(["btn-notification btn-notification-add", { 'none-hover': _ctx.contract.canUpdate }]),
                  enabled: _ctx.contract.canUpdate,
                  onOnClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('onAlertNotification', _ctx.contract)))
                }, null, 8, ["icon", "disabled-icon", "class", "enabled"]))
              : (_openBlock(), _createBlock(_component_text_button, {
                  key: 1,
                  icon: require('icons/add-notification.svg'),
                  "disabled-icon": require('icons/add-notification-grey.svg'),
                  text: _ctx.$t('folder.add'),
                  class: _normalizeClass(["btn-notification", { 'none-hover': _ctx.canAlertOrResendEmail }]),
                  enabled: _ctx.canAlertOrResendEmail,
                  onOnClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('onAlertNotification', _ctx.contract)))
                }, null, 8, ["icon", "disabled-icon", "text", "class", "enabled"]))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_TextButton, {
        class: "folder-person-access mr-1",
        icon: require('icons/primary-access-outline.svg'),
        text: _ctx.contract.countShare.toString(),
        "disabled-icon": require('icons/primary-access-outline-grey.svg'),
        enabled: _ctx.isPermitted || _ctx.isReceived,
        onOnClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('onEditAccessContract', _ctx.contract)))
      }, null, 8, ["icon", "text", "disabled-icon", "enabled"]),
      _createElementVNode("div", {
        class: _normalizeClass(["icon", { selected: _ctx.showMenuOptionId == _ctx.contract.id }]),
        onClick: _cache[31] || (_cache[31] = ($event: any) => (_ctx.handleShowMenuOption(_ctx.contract.id)))
      }, [
        _createElementVNode("img", {
          src: require('icons/more.svg'),
          alt: "more",
          disabled: ""
        }, null, 8, _hoisted_12),
        _withDirectives(_createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            (_ctx.contract.isFile)
              ? (_openBlock(), _createBlock(_component_menu_option_dropdown, { key: 0 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/details.svg'),
                        "disabled-icon": require('icons/details-grey.svg'),
                        text: _ctx.$t('folder.viewDetail'),
                        enabled: _ctx.isPermitted,
                        onOnClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('onViewDetailFile', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/edit.svg'),
                        "disabled-icon": require('icons/edit-grey.svg'),
                        text: _ctx.$t('folder.renameFile'),
                        enabled: _ctx.contract.canUpdate,
                        onOnClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('onRenameFile', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/download.svg'),
                        "disabled-icon": require('icons/download-grey.svg'),
                        text: _ctx.$t('folder.download'),
                        enabled: _ctx.contract.canUpdate,
                        onOnClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.handleDownloadFile(_ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/primary-access-outline.svg'),
                        "disabled-icon": 
                    require('icons/primary-access-outline-grey.svg')
                  ,
                        text: _ctx.$t('folder.editAccess'),
                        enabled: _ctx.isPermitted || _ctx.isReceived,
                        onOnClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.$emit('onEditAccessContract', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/move_icon.svg'),
                        "disabled-icon": require('icons/move_icon_grey.svg'),
                        text: _ctx.$t('folder.moveFolder'),
                        enabled: _ctx.contract.canUpdate,
                        onOnClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.$emit('onMoveContract', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/delete.svg'),
                        "disabled-icon": require('icons/delete-grey.svg'),
                        text: _ctx.$t('contact.delete'),
                        enabled: _ctx.canDelete,
                        onOnClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.$emit('onDeleteContract', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ])
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_menu_option_dropdown, { key: 1 }, {
                  default: _withCtx(() => [
                    (_ctx.isSigner)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          _createElementVNode("div", _hoisted_22, [
                            (!_ctx.contract.needToSignByXID)
                              ? (_openBlock(), _createBlock(_component_FlatButton, {
                                  key: 0,
                                  text: _ctx.$t('folder.sign'),
                                  icon: '',
                                  class: "contract-btn-sign",
                                  onOnClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.$emit('onSign', _ctx.contract.id)))
                                }, null, 8, ["text"]))
                              : (_openBlock(), _createBlock(_component_XIDButton, {
                                  key: 1,
                                  text: _ctx.$t('folder.sign'),
                                  class: "contract-btn-sign contract-btn-xid",
                                  onOnClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.$emit('onSign', _ctx.contract.id)))
                                }, null, 8, ["text"]))
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_23, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/details.svg'),
                        "disabled-icon": require('icons/details-grey.svg'),
                        text: _ctx.$t('folder.viewDetail'),
                        enabled: _ctx.isPermitted,
                        onOnClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.$emit('onViewDetail', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/edit.svg'),
                        "disabled-icon": require('icons/edit-grey.svg'),
                        text: _ctx.$t('folder.edit'),
                        enabled: _ctx.contract.canUpdate,
                        onOnClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.$emit('onEditContract')))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/edit.svg'),
                        "disabled-icon": require('icons/edit-grey.svg'),
                        text: _ctx.$t('folder.editInformation'),
                        enabled: _ctx.contract.canUpdate,
                        onOnClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.$emit('onEditInformationContract',_ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/download.svg'),
                        "disabled-icon": require('icons/download-grey.svg'),
                        text: _ctx.$t('folder.download'),
                        enabled: _ctx.contract.canUpdate,
                        onOnClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.handleDownloadFile(_ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/primary-access-outline.svg'),
                        "disabled-icon": 
                    require('icons/primary-access-outline-grey.svg')
                  ,
                        text: _ctx.$t('folder.editAccess'),
                        enabled: _ctx.isPermitted || _ctx.isReceived,
                        onOnClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.$emit('onEditAccessContract', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/move_icon.svg'),
                        "disabled-icon": require('icons/move_icon_grey.svg'),
                        text: _ctx.$t('folder.moveFolder'),
                        enabled: _ctx.contract.canUpdate,
                        onOnClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.$emit('onMoveContract', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/notification.svg'),
                        "disabled-icon": require('icons/notification-grey.svg'),
                        text: _ctx.$t('folder.alertNotification'),
                        enabled: _ctx.canAlertOrResendEmail,
                        onOnClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.$emit('onAlertNotification', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/re-invite.svg'),
                        "disabled-icon": require('icons/re-invite-grey.svg'),
                        text: _ctx.$t('folder.resendEmailToSigner'),
                        enabled: _ctx.canAlertOrResendEmail,
                        onOnClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.$emit('onResendEmail', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/transfer.svg'),
                        "disabled-icon": require('icons/transfer-grey.svg'),
                        text: _ctx.$t('folder.transferSigner'),
                        enabled: _ctx.isSigner,
                        onOnClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.$emit('onTransferContract', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/cancel.svg'),
                        "disabled-icon": require('icons/cancel-grey.svg'),
                        text: _ctx.$t('folder.rejectContract'),
                        enabled: _ctx.isSigner,
                        onOnClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.$emit('onRejectContract', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _createVNode(_component_TextButton, {
                        icon: require('icons/delete.svg'),
                        "disabled-icon": require('icons/delete-grey.svg'),
                        text: _ctx.$t('contact.delete'),
                        enabled: _ctx.canDelete,
                        onOnClick: _cache[30] || (_cache[30] = ($event: any) => (_ctx.$emit('onDeleteContract', _ctx.contract)))
                      }, null, 8, ["icon", "disabled-icon", "text", "enabled"])
                    ])
                  ]),
                  _: 1
                }))
          ])
        ], 512), [
          [_vShow, _ctx.showMenuOptionId == _ctx.contract.id]
        ])
      ], 2)
    ])
  ], 34))
}
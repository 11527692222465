import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28313c38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = { class: "input-label" }
const _hoisted_3 = { class: "input-label" }
const _hoisted_4 = { class: "two-input-row" }
const _hoisted_5 = { class: "haft-line" }
const _hoisted_6 = { class: "input-label" }
const _hoisted_7 = { class: "haft-line" }
const _hoisted_8 = { class: "input-label" }
const _hoisted_9 = { class: "two-input-row" }
const _hoisted_10 = { class: "haft-line" }
const _hoisted_11 = { class: "input-label" }
const _hoisted_12 = { class: "haft-line" }
const _hoisted_13 = { class: "input-label" }
const _hoisted_14 = { class: "date-picker message" }
const _hoisted_15 = { class: "two-input-row" }
const _hoisted_16 = { class: "haft-line" }
const _hoisted_17 = { class: "input-label" }
const _hoisted_18 = { class: "haft-line" }
const _hoisted_19 = { class: "input-label" }
const _hoisted_20 = { class: "input-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_text_field = _resolveComponent("validation-text-field")!
  const _component_CustomDatepicker = _resolveComponent("CustomDatepicker")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.popupState)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.title,
        onOnClickOutsidePopup: _ctx.hidePopup,
        onOnHide: _ctx.hidePopup
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("common.optionalField") +
            " " +
            _ctx.$t("contractSummary.contractSummary")), 1),
              _createVNode(_component_validation_text_field, {
                name: "contractOverview",
                "max-length": 200,
                placeholder: '',
                value: _ctx.contractOverview,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contractOverview) = $event)),
                "keep-error-space": false
              }, null, 8, ["value"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("common.optionalField") +
            " " +
            _ctx.$t("contractSummary.contractExpiredTime")), 1),
              _createVNode(_component_CustomDatepicker, {
                name: "contractConclusionDate",
                class: "date-picke message",
                type: "text",
                placeholder: _ctx.$t('contractSummary.datePickerPlaceholder'),
                "is-black": true,
                value: _ctx.contractConclusionDate,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contractConclusionDate) = $event))
              }, null, 8, ["placeholder", "value"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("common.optionalField") +
              " " +
              _ctx.$t("contractSummary.contractStart")), 1),
                _createVNode(_component_CustomDatepicker, {
                  name: "contractStartDate",
                  class: "date-picker message",
                  type: "text",
                  placeholder: _ctx.$t('contractSummary.datePickerPlaceholder'),
                  "is-black": true,
                  "upper-limit-date": 
              _ctx.contractEndDate ? new Date(_ctx.contractEndDate) : null
            ,
                  value: _ctx.contractStartDate,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.contractStartDate) = $event))
                }, null, 8, ["placeholder", "upper-limit-date", "value"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("common.optionalField") +
              " " +
              _ctx.$t("contractSummary.contractEnd")), 1),
                _createVNode(_component_CustomDatepicker, {
                  name: "contractEndDate",
                  class: "date-picker message",
                  type: "text",
                  placeholder: _ctx.$t('contractSummary.datePickerPlaceholder'),
                  "is-black": true,
                  "lower-limit-date": 
              _ctx.contractStartDate ? new Date(_ctx.contractStartDate) : null
            ,
                  value: _ctx.contractEndDate,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contractEndDate) = $event))
                }, null, 8, ["placeholder", "lower-limit-date", "value"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("common.optionalField") +
              " " +
              _ctx.$t("contractSummary.cancelExpiredTime")), 1),
                _createVNode(_component_validation_text_field, {
                  name: "terminationNoticePeriod",
                  "keep-error-space": false,
                  placeholder: '',
                  value: _ctx.terminationNoticePeriod,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.terminationNoticePeriod) = $event))
                }, null, 8, ["value"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("common.optionalField") +
              " " +
              _ctx.$t("contractSummary.autoUpdate")), 1),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_Dropdown, {
                    name: "isAutomaticallyUpdated",
                    options: _ctx.automaticOptions,
                    "default-option": _ctx.isAutomaticallyUpdated,
                    value: _ctx.isAutomaticallyUpdated,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isAutomaticallyUpdated) = $event)),
                    class: "w-full",
                    "place-holder": _ctx.$t('common.selectPlaceHolder')
                  }, null, 8, ["options", "default-option", "value", "place-holder"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("common.optionalField") +
              " " +
              _ctx.$t("contractSummary.controlNumber")), 1),
                _createVNode(_component_validation_text_field, {
                  name: "controlNumber",
                  "keep-error-space": false,
                  placeholder: '',
                  "max-length": 100,
                  message: {
              error_format_control_number: _ctx.$t('errors.formatControlNumber'),
            },
                  rules: "controlNumber",
                  value: _ctx.controlNumber,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.controlNumber) = $event))
                }, null, 8, ["message", "value"])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("common.optionalField") +
              " " +
              _ctx.$t("contractSummary.transactionAmount")), 1),
                _createVNode(_component_validation_text_field, {
                  name: "transactionAmount",
                  "keep-error-space": false,
                  placeholder: '',
                  value: _ctx.transactionAmount,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.transactionAmount) = $event)),
                  type: "text",
                  rules: "number",
                  "max-length": 10,
                  message: {
              error_format_number: _ctx.$t('errors.formatNumber'),
            }
                }, null, 8, ["value", "message"])
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t("common.optionalField") +
            " " +
            _ctx.$t("contractSummary.freeText") +
            _ctx.$t("contractSummary.numberOfFreeText")), 1),
              _createVNode(_component_validation_text_field, {
                name: "freeText",
                value: _ctx.freeText,
                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.freeText) = $event)),
                placeholder: '',
                "is-multiline": true,
                class: "multiline-input",
                "max-length": 2000,
                "keep-error-space": false
              }, null, 8, ["value"])
            ]),
            _createVNode(_component_flat_button, {
              "on-click": _ctx.onEdit,
              text: _ctx.$t('common.changeTo'),
              class: "U-btn"
            }, null, 8, ["on-click", "text"])
          ])
        ]),
        _: 1
      }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}
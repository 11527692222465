
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
  inject,
  onBeforeMount,
  onMounted,
} from "vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import TableSelect from "@/components/atomics/TableSelect.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import Popup from "@/components/popups/Popup.vue";
import { RepositoryFactory, ContractRepository } from "@/lib/https";
import {
  MemberPermissionsI,
  GroupPermissionsI,
  ContractI,
} from "@/models/Folder";
import { mapMutations, useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ReadWriteRole, isReadWrite, isOwner, isReceivedFolder } from "@/lib/utility/permission";

export default defineComponent({
  name: "ContractEditMemberPopup",
  components: {
    Dropdown,
    FlatButton,
    TableSelect,
    Popup,
  },
  props: {
    folderId: {type: String, default: ""},
    title: { type: String, required: true },
    contractItem: { type: Object, requried: true },
  },
  setup(props, context) {
    const store = useStore();
    const FolderPermissions = inject("FolderPermissions");
    const { t } = useI18n();

    const state = reactive({
      selectedPermission: ReadWriteRole.READ_WRITE,
      isReceivedFolder: isReceivedFolder(props.folderId),
      permissions: FolderPermissions,
      readOnly: [{ id: 2, name: t('folder.readOnly') }],
      userPermissions: [] as any[],
      groupPermissions: [] as any[],
      userSearch: [],
      groupSearch: [],
      selectedUserGroup: [] as (MemberPermissionsI | GroupPermissionsI)[],
      delay: "",
      avatarBaseUrl: process.env.VUE_APP_API_CLOUD_URL,
      isLoading: false,
      updateRoleData: [] as any[],
      deleteRoleData: [] as any[],
    });

    const contractItem = props.contractItem as ContractI;

    const {
      getUsersAndGroups,
      getContractPermissions,
      addContractMemberGroupPermission,
      updateContractGroupMemeberPermission,
      deleteContractGroupMemeberPermission,
    } = RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

    const addTagGroupUser = (lst: any, isUser = true) => {
      return lst.map((item: any) => ({
        ...item,
        userId: isUser ? item.id : "",
        groupId: !isUser ? item.id : "",
      }));
    };

    const getLastestPopupData = async () => {
      const contractItem = props.contractItem as ContractI;
      if (!contractItem.canUpdate) {
        state.selectedPermission = ReadWriteRole.READ_ONLY;
      }
      const response = await Promise.all([
        getContractPermissions(contractItem.id),
        getUsersAndGroups({ contractId: contractItem.id }),
      ]);
      if (response) {
        const [contractPermissionList, userGroupList] = response;
        if (contractPermissionList && userGroupList) {
          state.userPermissions = addTagGroupUser(contractPermissionList.users);
          state.groupPermissions = addTagGroupUser(
            contractPermissionList.groups,
            false
          );
          state.userSearch = addTagGroupUser(userGroupList.users.data);
          state.groupSearch = addTagGroupUser(userGroupList.groups.data, false);
        }
      }
    };

    watch(
      () => props.contractItem,
      async () => {
        getLastestPopupData();
      }
    );

    const onAddContractPermission = async () => {
      const editRolePromise: Promise<any>[] = [];
      if (state.selectedUserGroup.length > 0) {
        state.selectedUserGroup.forEach((item: any) => {
          item.role = Number(state.selectedPermission);
          item.contractId = props.contractItem?.id;
          editRolePromise.push(addContractMemberGroupPermission(item));
        });
      }

      if (state.updateRoleData.length > 0) {
        state.updateRoleData.forEach((item: any) => {
          editRolePromise.push(updateContractGroupMemeberPermission(item));
        });
      }

      if (state.deleteRoleData.length > 0) {
        state.deleteRoleData.forEach((item: any) => {
          editRolePromise.push(
            deleteContractGroupMemeberPermission(item.contractItem, item.item)
          );
        });
      }

      if (editRolePromise.length > 0) {
        await Promise.all(editRolePromise)
          .then((res) => {
            context.emit("onSubmitEditAccessContract", res);
            state.selectedPermission = ReadWriteRole.READ_WRITE;
            resetPopup();
          })
          .catch((err) => {
            context.emit("onSubmitEditAccessContract", err);
          });
        store.commit("popup/unsetPopup", "ContractEditAccess");
      } else {
        store.commit("popup/unsetPopup", "ContractEditAccess");
      }
    };

    const resetPopup = () => {
      state.selectedPermission = ReadWriteRole.READ_WRITE;
      state.selectedUserGroup = [];
      state.userPermissions = [];
      state.groupPermissions = [];
      state.userSearch = [];
      state.groupSearch = [];
      state.updateRoleData = [];
      state.deleteRoleData = [];
    };

    const onUpdateRole = async (roleId: number, item: any) => {
      const roleItem = state.updateRoleData.find((data) => data.id === item.id);
      if (roleItem) {
        if (item.role === roleId) {
          state.updateRoleData = state.updateRoleData.filter(
            (data) => data.id !== item.id
          );
        } else {
          roleItem.role = roleId;
        }
      } else {
        state.updateRoleData.push({
          ...item,
          contractId: props.contractItem?.id,
          role: roleId,
        });
      }
    };

    const onDeletePermission = async (item: any) => {
      state.updateRoleData = state.updateRoleData.filter(
        (data) => data.id !== item.id
      );
      state.userPermissions = state.userPermissions.filter(
        (data) => data.id !== item.id
      );
      state.groupPermissions = state.groupPermissions.filter(
        (data) => data.id !== item.id
      );
      state.deleteRoleData.push({
        item,
        contractItem: props.contractItem,
      });
    };

    let delay: ReturnType<typeof setTimeout>;
    const onSearchChange = (searchKeyword: any) => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        const contractItem = props.contractItem as ContractI;
        const response = await getUsersAndGroups({
          contractId: contractItem.id,
          keyword: searchKeyword,
        });
        if (response) {
          state.userSearch = addTagGroupUser(response.users.data);
          state.groupSearch = addTagGroupUser(response.groups.data, false);
        }
      }, 300);
    };

    const scrollToBottom = (value: boolean) => {
      if (!value) return;
      let item = document.querySelector(".popup-container");
      item?.scrollTo({
        top: item.scrollHeight,
        behavior: "smooth",
      });
    };

    return {
      ...toRefs(state),
      popupState: computed(() => store.state.popup.ContractEditAccess),
      onSearchChange,
      onAddContractPermission,
      onUpdateRole,
      onDeletePermission,
      resetPopup,
      scrollToBottom,
      isReadWrite,
      ReadWriteRole,
      isOwner,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),
    hidePopup(): void {
      this.unsetPopup("ContractEditAccess");
      this.resetPopup();
    },

    handleChangePermission(id: any) {
      this.selectedPermission = id;
    },

    handleSelectUserGroup(selectedItems: any) {
      this.selectedUserGroup = selectedItems;
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */

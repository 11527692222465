
import { computed, defineComponent, reactive, toRefs, watch } from "vue";

import { mapMutations, useStore } from "vuex";
import Popup from "@/components/popups/Popup.vue";

import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import CustomDatepicker from "@/components/atomics/CustomDatepicker.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";

import {
  ContractRepository,
  RepositoryFactory,
  TemplateRepository,
} from "@/lib/https";
import { useI18n } from "vue-i18n";
import useMapAndStore from "@/lib/compositional-logic/useMapAndStore";
import { TemplateInformation } from "@/models/contracts/Information";
import { formatDate } from "@/lib/utility/common";

export default defineComponent({
  name: "ContractEditInformationPopup",
  components: {
    Popup,
    ValidationTextField,
    Dropdown,
    CustomDatepicker,
    FlatButton,
  },
  emits: ["onHide"],
  props: {
    contractId: { type: String, required: true },
    title: { type: String, required: true },
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const state = reactive({
      automaticOptions: [
        {
          id: true,
          name: t("template.updateOption1"),
        },
        {
          id: false,
          name: t("template.updateOption2"),
        },
      ],
      contractId: props.contractId,
      companyId: "",
      contractOverview: "",
      contractConclusionDate: null,
      contractStartDate: null,
      contractEndDate: null,
      terminationNoticePeriod: "",
      isAutomaticallyUpdated: null,
      controlNumber: "",
      transactionAmount: 0,
      freeText: "",
    });

    const { parseContractDate } = useMapAndStore();
    const popupState = computed(() => store.state.popup.ContractInformation);
    const { getContractInformation, updateContractInformation } =
      RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);

    const resetPopup = () => {
      state.contractOverview = "";
      state.contractConclusionDate = null;
      state.contractStartDate = null;
      state.contractEndDate = null;
      state.terminationNoticePeriod = "";
      state.isAutomaticallyUpdated = null;
      state.controlNumber = "";
      state.transactionAmount = 0;
      state.freeText = "";
    };

    const showSuccessNotification = (text: string) => {
      store.commit("notification/showSuccessNotification", text);
    };

    const showErrorNotification = (text: string) => {
      store.commit("notification/showErrorNotification", text);
    };

    const onEdit = async () => {
      const data = new TemplateInformation(
        state.contractId,
        state.contractOverview ? state.contractOverview : "",

        state.contractConclusionDate
          ? (formatDate(state.contractConclusionDate, "YYYY-MM-DD") as any)
          : null,
        state.contractStartDate
          ? (formatDate(state.contractStartDate, "YYYY-MM-DD") as any)
          : null,
        state.contractEndDate
          ? (formatDate(state.contractEndDate, "YYYY-MM-DD") as any)
          : null,
        state.terminationNoticePeriod ? state.terminationNoticePeriod : "",
        typeof state.isAutomaticallyUpdated == "boolean"
          ? (state.isAutomaticallyUpdated as any)
          : null,
        state.controlNumber ? state.controlNumber : "",
        state.transactionAmount ? Number(state.transactionAmount) : 0,
        state.freeText ? state.freeText : ""
      );
      data.companyId = state.companyId;
      try {
        await updateContractInformation(data);
        showSuccessNotification(t("notification.updateSuccess"));
        store.commit("popup/unsetPopup", "ContractInformation");
      } catch (error: any) {
        showErrorNotification(error.data.message);
        store.commit("popup/unsetPopup", "ContractInformation");
      }
    };

    watch(
      () => [props.contractId, popupState.value],
      async (val) => {
        if (val[0] && val[1]) {
          const accountInfo = store.getters["header/getAccountInfo"];
          resetPopup();
          try {
            const res = await getContractInformation(val[0]);
            state.contractId = res.contractId;
            (state.companyId = res.companyId),
              (state.contractOverview = res.contractOverview ?? "");
            state.contractConclusionDate = parseContractDate(
              res.contractConclusionDate
            ) as any;
            state.contractStartDate = parseContractDate(
              res.contractStartDate
            ) as any;
            state.contractEndDate = parseContractDate(
              res.contractEndDate
            ) as any;
            state.terminationNoticePeriod = res.terminationNoticePeriod ?? "";
            state.transactionAmount = res.transactionAmount ?? 0;
            state.isAutomaticallyUpdated = res.isAutomaticallyUpdated;
            state.controlNumber = res.controlNumber ?? "";
            state.freeText = res.freeText ?? "";
          } catch (error: any) {
            showErrorNotification(error.data.message);
            store.commit("popup/unsetPopup", "ContractInformation");
          }
        }
      }
    );

    return {
      ...toRefs(state),
      popupState,
      resetPopup,
      onEdit,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),
    hidePopup(): void {
      this.unsetPopup("ContractInformation");
      this.resetPopup();
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */

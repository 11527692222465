
import { defineComponent, inject, computed, reactive, toRefs } from "vue";

import FlatButton from "@/components/atomics/FlatButton.vue";
import Popup from "@/components/popups/Popup.vue";
import { mapMutations, useStore } from "vuex";
import { useI18n } from "vue-i18n";
import {
  FolderRepository,
  ContractRepository,
  RepositoryFactory,
} from "@/lib/https";

export default defineComponent({
  name: "ConfirmDelete",
  components: {
    FlatButton,
    Popup,
  },
  props: {
    folderItem: { type: Object },
    contractItem: { type: Object },
    selectedContractList: { type: Array },
    selectedFolderList: { type: Array },
    confirmSelectedPopup: { type: Number, requires: true },
  },
  emits: ["onSubmitDeleteFolderContract"],
  setup(props, context) {
    const state = reactive({
      isLoading: false,
    });
    const { deleteFolder } =
      RepositoryFactory.getRepository<FolderRepository>(FolderRepository);
    const { deleteContract, deleteManyFolderContract } =
      RepositoryFactory.getRepository<ContractRepository>(ContractRepository);
    const store = useStore();
    const DeletePopupList = inject("DeletePopupList") as any;
    const { t } = useI18n();

    const get_title = computed(() => {
      if (
        props.confirmSelectedPopup === DeletePopupList.DELETE_FOLDER ||
        props.confirmSelectedPopup ===
          DeletePopupList.DELETE_SIGNED_CONTRACT_ERROR ||
        props.confirmSelectedPopup ===
          DeletePopupList.DELETE_MULTIPLE_SIGNED_CONTRACT_ERROR
      ) {
        return t("folder.deleteFolderTitle");
      } else if (
        props.confirmSelectedPopup === DeletePopupList.DELETE_CONTRACT
      ) {
        if (props.contractItem?.isFile || props.contractItem?.isDocxTemplate) {
          return t("folder.deleteFileTitle");
        }
        return t("folder.deleteContractTitle");
      } else if(
        props.confirmSelectedPopup === DeletePopupList.DELETE_TEMPLATE
      ) {
        return t("template.deleteTemplatePopupTitle");
      } else {
        return t("folder.deleteMultipleTitle");
      }
    });

    const confirm = async () => {
      let payload;
      try {
        let res;
        if (props.confirmSelectedPopup === DeletePopupList.DELETE_FOLDER) {
          payload = props.folderItem?.id;
          res = await deleteFolder(payload);
        } else if (
          props.confirmSelectedPopup === DeletePopupList.DELETE_CONTRACT || props.confirmSelectedPopup === DeletePopupList.DELETE_TEMPLATE
        ) {
          payload = props.contractItem?.id;
          res = await deleteContract(payload);
        } else if (
          props.confirmSelectedPopup === DeletePopupList.DELETE_MULTIPLE
        ) {
          const ids = props.selectedContractList?.map((item: any) => item.id);
          const folderIds = props.selectedFolderList?.map(
            (item: any) => item.id
          );
          payload = {
            ids,
            folderIds,
          };
          res = await deleteManyFolderContract(payload);
        }
        if (res) {
          context.emit("onSubmitDeleteFolderContract", { res });
        }
        store.commit("popup/unsetPopup", "ConfirmDelete");
      } catch (err) {
        context.emit("onSubmitDeleteFolderContract", { err });
      }
    };

    return {
      ...toRefs(state),
      confirm,
      get_title,
      DeletePopupList,
      popupState: computed(() => store.state.popup.ConfirmDelete),
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),
    hidePopup(): void {
      this.unsetPopup("ConfirmDelete");
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */

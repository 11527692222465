export interface MemberPermissionsI {
  folderId?: string;
  userId?: string;
  role?: number;
}

export interface GroupPermissionsI {
  folderId?: string;
  groupId?: string;
  role?: number;
}

export interface FolderI {
  id?: string;
  name: string;
  isInheritedPermission?: boolean;
  isTemplateFolder?: boolean;
  parentFolderId?: string;
  folderMemberPermissions?: MemberPermissionsI[];
  folderGroupPermissions?: GroupPermissionsI[];
  canUpdate?: boolean;
  canDelete?: boolean;
  role?: number;
}

export interface FolderPaginationI {
  page: number;
  pageSize: number;
  totalRecords: number;
  role?: number;
  roleName?: string;
  canCreate: boolean;
  bookmark: number;
}

export interface ContractI {
  id: string;
  companyId: string;
  title: string;
  updatedAt: string;
  countShare: number;
  role: number;
  status: number;
  statusName: string;
  needToSignByXID: boolean;
  contractNotificationId: string;
  canUpdate: boolean;
  canDelete: boolean;
  isCreator: boolean;
  isSigner: boolean;
  isViewer: boolean;
  countSigned: number;
  signerOrder: number;
  isFile: boolean;
}

export class Folder implements FolderI {
  "id" = "";
  "name" = "";
  "isInheritedPermission" = false;
  "isTemplateFolder" = false;
  "parentFolderId" = "";
  "folderMemberPermissions": MemberPermissionsI[] = [];
  "folderGroupPermissions": GroupPermissionsI[] = [];
  "role" = 0;
  constructor(folder?: FolderI) {
    Object.assign(this, folder);
  }
}

export class Contract implements ContractI {
  "id" = "";
  "companyId" = "";
  "title" = "";
  "updatedAt" = "";
  "countShare" = 0;
  "role" = 0;
  "status" = 0;
  "statusName" = "";
  "needToSignByXID" = false;
  "contractNotificationId" = "";
  "canUpdate" = false;
  "canDelete" = false;
  "isCreator" = false;
  "isSigner" = false;
  "isViewer" = false;
  "countSigned" = 0;
  "signerOrder" = 0;
  "isFile" = false;
  constructor(contract?: ContractI) {
    Object.assign(this, contract);
  }
}

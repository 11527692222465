
import {
  defineComponent,
  reactive,
  toRefs,
  inject,
  onMounted,
  provide,
  onBeforeMount,
  computed,
  watch,
  ref,
} from "vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import FolderItem from "@/views/pages/folder/FolderItem.vue";
import ContractItem from "@/views/pages/folder/ContractItem.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import LoadMore from "@/components/atomics/LoadMore.vue";
import FolderRenamePopup from "@/components/popups/folder/FolderRenamePopup.vue";
import FolderAddPopup from "@/components/popups/folder/FolderAddPopup.vue";
import FolderEditMemberPopup from "@/components/popups/folder/FolderEditMemberPopup.vue";
import FolderContractMovePopup from "@/components/popups/folder/FolderContractMovePopup.vue";
import EditFolderNotificationPopup from "@/components/popups/folder-list-notification/EditFolderNotificationPopup.vue";
import DeleteFolderNotificationPopup from "@/components/popups/folder-list-notification/DeleteFolderNotificationPopup.vue";
import ContractTransferPopup from "@/components/popups/contract-transfer/ContractTransferPopup.vue";
import ContractEditMemberPopup from "@/components/popups/folder/ContractEditMemberPopup.vue";
import ContractTemplateSelectPopup from "@/components/popups/contract/ContractTemplateSelectPopup.vue";
import ConfirmDeletePopup from "@/components/popups/folder/ConfirmDeletePopup.vue";
import ContractEditInformationPopup from "@/components/popups/contract/ContractEditInformationPopup.vue";
import RejectSignPopup from "@/components/popups/sign/RejectSignPopup.vue";

import AccessCodePopup from "@/components/popups/sign/AccessCodePopup.vue";
import RequestCodePopup from "@/components/popups/sign/RequestCodePopup.vue";
import SignCodePopup from "@/components/popups/sign/SignCodePopup.vue";

import RequestLoginPopup from "@/components/popups/contract-xID/RequestLoginPopup.vue";
import ContractSelectPopup from "@/components/popups/contract-xID/ContractSelectPopup.vue";
import ContractPinPopup from "@/components/popups/contract-xID/ContractPinPopup.vue";

import ToolTip from "@/components/atomics/ToolTip.vue";
import {
  RepositoryFactory,
  FolderRepository,
  TemplateRepository,
  ContractRepository,
  AuthRepository,
} from "@/lib/https";
import { useRoute, useRouter } from "vue-router";
import { mapMutations, useStore } from "vuex";
import useMapAndStore from "@/lib/compositional-logic/useMapAndStore";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";
import useFolderContractHandler from "@/lib/compositional-logic/useFolderContractHandler";
import { getListFolderFromPath, getFileExtension } from "@/lib/utility/common";
import {
  Folder,
  Contract,
  FolderI,
  ContractI,
  FolderPaginationI,
} from "@/models/Folder";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import useContractFromTemplate from "@/lib/compositional-logic/useContractFromTemplate";
import { useI18n } from "vue-i18n";
import { FileInfo } from "@/models/contracts/FileInfo";
import { useForm } from "vee-validate";
import {
  UserRole,
  ReadWriteRole,
  isNeedToSign,
  canContractUpdate,
  canContractDelete,
} from "@/lib/utility/permission";

interface BreadScrumInterface {
  id?: string;
  text: string;
  to: string;
}

enum SortOption {
  NAME_ASC = "name ASC",
  NAME_DESC = "name DESC",
  UPDATED_ASC = "updatedAt ASC",
  UPDATED_DESC = "updatedAt DESC",
}

enum DeletePopupList {
  DELETE_FOLDER,
  DELETE_CONTRACT,
  DELETE_MULTIPLE,
  DELETE_SIGNED_CONTRACT_ERROR,
  DELETE_MULTIPLE_SIGNED_CONTRACT_ERROR,
}

enum RenamePopupList {
  RENAME_FOLDER,
  RENAME_CONTRACT,
}

enum MovePopupList {
  MOVE_FOLDER,
  MOVE_CONTRACT,
  MOVE_MULTIPLE,
}

enum CONTRACT_STATUS {
  DRAFT = 0,
  CONFIRM_REQUIRED = 1,
  CHECKING = 2,
  CONCLUDED = 3,
  REJECTED = 4,
}

export default defineComponent({
  name: "Folder",
  components: {
    BreadScrum,
    MenuOptionDropdown,
    FolderItem,
    ContractItem,
    TextButton,
    FlatButton,
    Dropdown,
    LoadMore,
    FolderRenamePopup,
    FolderAddPopup,
    FolderEditMemberPopup,
    FolderContractMovePopup,
    EditFolderNotificationPopup,
    DeleteFolderNotificationPopup,
    ContractTransferPopup,
    ContractEditMemberPopup,
    AfterLoginPageLayout,
    ContractTemplateSelectPopup,
    ConfirmDeletePopup,
    ToolTip,
    ContractEditInformationPopup,
    RejectSignPopup,
    AccessCodePopup,
    RequestCodePopup,
    SignCodePopup,

    RequestLoginPopup,
    ContractSelectPopup,
    ContractPinPopup,
  },
  props: {
    folderPath: { type: String, default: "" },
    withError: {
      type: String,
      default: "",
    },
    withSuccess: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { t } = useI18n();
    const ROOT_BREADSCRUM_NAME = t("folder.allFolder");
    const route = useRoute();
    const { folderId, folderTitle, status } = route.query;
    const filterStatus = {
      "draft": CONTRACT_STATUS.DRAFT,
      "confirm-request": CONTRACT_STATUS.CONFIRM_REQUIRED,
      "checking": CONTRACT_STATUS.CHECKING,
      "concluded": CONTRACT_STATUS.CONCLUDED,
      "rejected": CONTRACT_STATUS.REJECTED,
      "": -1,
    };

    const state = reactive({
      breadScrums: [
        {
          id: "",
          text: ROOT_BREADSCRUM_NAME,
          to: "/folder?status=" + status,
        },
      ] as BreadScrumInterface[],
      showMenuOptionId: "",
      renameFolderItem: new Folder(),
      renameContractItem: new Contract(),
      editAccessFolderItem: new Folder(),
      moveFolderItem: new Folder(),
      deleteNotificationId: "",
      editInforContract: new Contract(),
      moveContractItem: new Contract(),
      notificationContractItem: new Contract(),
      deleteFolderItem: new Folder(),
      deleteContractItem: new Contract(),
      rejectContractItem: new Contract(),
      filterStatusSelected: -1,
      filterStatusSelectedName: "",
      folderPermission: [
        {
          id: ReadWriteRole.READ_WRITE,
          name: t("folder.readWrite"),
        },
        {
          id: ReadWriteRole.READ_ONLY,
          name: t("folder.readOnly"),
        },
      ],
      filterStatusOptions: [
        {
          id: -1,
          name: t("folder.filterAll"),
        },
        {
          id: 1,
          name: t("folder.filter1"),
        },
        {
          id: 2,
          name: t("folder.filter2"),
        },
        {
          id: 3,
          name: t("folder.filter3"),
        },
        {
          id: 0,
          name: t("folder.filter0"),
        },
        {
          id: 4,
          name: t("folder.filter4"),
        },
      ],
      foldersData: [] as Array<FolderI>,
      foldersPagination: {} as FolderPaginationI,
      contractsPagination: {} as any,
      contractsData: [] as Array<ContractI>,
      selectedContractList: [] as Array<ContractI>,
      selectedFolderList: [] as Array<FolderI>,
      sortSelected: SortOption.NAME_ASC,
      sortOptions: [
        {
          text: t("folder.sortNameAsc"),
          value: SortOption.NAME_ASC,
        },
        {
          text: t("folder.sortNameDesc"),
          value: SortOption.NAME_DESC,
        },
        {
          text: t("folder.sortUpdatedAsc"),
          value: SortOption.UPDATED_ASC,
        },
        {
          text: t("folder.sortUpdatedDesc"),
          value: SortOption.UPDATED_DESC,
        },
      ],
      isShowDeleteFolderNotificationPopup: false,
      isShowEditFolderNotificationPopup: true,
      isAddNotification: false,
      transferContractItem: new Contract(),
      editAccessContractItem: new Contract(),
      editContractInformation: new Contract(),
      // pagination state
      pageFolder: 1,
      pageContract: 0,
      totalFolder: -1,
      totalContract: -1,
      isLoading: false,
      confirmSelectedPopup: DeletePopupList.DELETE_FOLDER,
      renameSelectedPopup: RenamePopupList.RENAME_FOLDER,
      moveSelectedPopup: MovePopupList.MOVE_FOLDER,
      currentFolderPath: "",
      isShowAccessCodePopup: false,
      isShiftPress: false,
      draggedItem: "",
      dragEnterItem: "",
    });

    const store = useStore();
    const router = useRouter();

    const isShownPopupState = reactive({
      isShownSortOption: false,
      showContractTransfer: false,
      isShownMenuOption: false,
      showTooltipNotification: false,
      showTooltipAccess: false,
    });

    const accountInfo = store.getters["header/getAccountInfo"];

    const { saveAccessCode, getAccessCode } = useSignFunctions();

    const showSuccessNotification = (text: string) => {
      store.commit("notification/showSuccessNotification", text);
    };

    const showErrorNotification = (text: string) => {
      store.commit("notification/showErrorNotification", text);
    };

    const totalItems = computed(() => state.totalContract);

    watch(
      () => totalItems.value,
      (newVal) => {
        const lastBreadScrum = state.breadScrums[
          state.breadScrums.length - 1
        ] as any;
        const listFolderPath = getListFolderFromPath(state.currentFolderPath);
        const lastPathElement = listFolderPath.pop() as any;
        if (state.breadScrums.length > 1) {
          state.breadScrums[0].text = ROOT_BREADSCRUM_NAME;
          lastBreadScrum.text = `${lastPathElement?.name} ${
            newVal > 0 ? "(" + newVal + ")" : ""
          }`;
        } else {
          lastBreadScrum.text = `${ROOT_BREADSCRUM_NAME} ${
            newVal > 0 ? "(" + newVal + ")" : ""
          }`;
        }
      },
      { immediate: true }
    );

    provide("ReadWriteRole", ReadWriteRole);
    provide("FolderPermissions", state.folderPermission);
    provide("DeletePopupList", DeletePopupList);
    provide("RenamePopupList", RenamePopupList);
    provide("MovePopupList", MovePopupList);

    const isMobile = inject("isMobile");

    const { createContractFromTemplate } = useContractFromTemplate();

    const { getListFolderContract } =
      RepositoryFactory.getRepository<FolderRepository>(FolderRepository);

    const { getContractsFullInformation } =
      RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);

    const { uploadContract, resendSignEmail } =
      RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

    const { checkXIDInformation } =
      RepositoryFactory.getRepository<AuthRepository>(AuthRepository);

    const {
      mapLocalBasicInformation,
      mapLocalDetailInformation,
      mapCloudFileToLocalFile,
      mapLocalSignerFromCloudSigner,
      mapLocalSecurityFromCloud,
      mapLocalViewerFromCloudViewer,
      mapLocalNotification,
    } = useMapAndStore();

    const { onDragItemStart, onDragItemEnd, onDragItemEnter, onDropItem } =
      useFolderContractHandler(state);

    const PAGE_SIZE_DEFAULT = 20;

    const loadFolderContract = async (condition = {}) => {
      const baseCondition = {
        page: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: state.sortSelected,
        folderId: folderId as string,
        keyword: "",
        ...condition,
      };
      const response = await getListFolderContract(baseCondition).catch((e) => {
        router.push({
          name: "ErrorPage",
          query: {
            error: e.data.message,
          },
        });
      });
      return response;
    };

    const reloadContractFolder = async ({
      reloadFolder = false,
      reloadContract = false,
    }) => {
      const contractFolderPromise: Promise<any>[] = [];
      if (reloadFolder) {
        contractFolderPromise.push(
          loadFolderContract({
            page: 1,
            pageSize: state.pageFolder * PAGE_SIZE_DEFAULT,
          })
        );
      }
      if (reloadContract) {
        contractFolderPromise.push(
          loadFolderContract({
            page: 1,
            pageSize: state.pageContract * PAGE_SIZE_DEFAULT,
          })
        );
      }
      const response = await Promise.all(contractFolderPromise);
      state.totalFolder = response[0].folders.pagination.totalRecords ?? 0;
      state.totalContract = response[0].contracts.pagination.totalRecords ?? 0;

      if (reloadFolder && reloadContract) {
        state.foldersData = response[0].folders.data;
        state.contractsData = response[1].contracts.data;
      } else {
        if (reloadFolder) {
          state.foldersData = response[0].folders.data;
        } else if (reloadContract) {
          state.contractsData = response[0].contracts.data;
        }
      }
    };

    const loadMore = async (onScroll: boolean) => {
      const currentPage = Math.ceil(
        state.foldersData.length / PAGE_SIZE_DEFAULT
      );
      const isNextPage = state.foldersData.length % PAGE_SIZE_DEFAULT === 0;
      if (isNextPage) state.pageFolder = currentPage + 1;
      else state.pageFolder = currentPage;
      if (!state.isLoading) {
        state.isLoading = true;
        const moreFolders: any = await loadFolderContract({
          page: state.pageFolder,
          bookmark: state.foldersPagination.bookmark as any,
        });
        for (const index in moreFolders.folders.data) {
          const isContain = state.foldersData.some(
            (folder: any) => folder.id === moreFolders.folders.data[index].id
          );
          if (
            !isContain &&
            moreFolders.folders.data[index].id != "received-folder"
          ) {
            state.foldersData.push(moreFolders.folders.data[index]);
          }
        }
        state.foldersPagination.bookmark =
          moreFolders.folders.pagination.bookmark || 0;
        state.isLoading = false;

        const currentPageContract = Math.ceil(
          state.contractsData.length / PAGE_SIZE_DEFAULT
        );
        const isNextPageContract =
          state.contractsData.length % PAGE_SIZE_DEFAULT === 0;
        if (isNextPageContract) state.pageContract = currentPageContract + 1;
        else state.pageContract = currentPageContract;
        if (state.totalFolder <= state.foldersData.length) {
          state.isLoading = true;
          const moreContracts: any = await loadFolderContract({
            page: state.pageContract,
            bookmark: state.contractsPagination.bookmark as any,
          });

          if (
            moreContracts.contracts.pagination.totalRecords >
            state.totalContract
          ) {
            state.totalContract =
              moreContracts.contracts.pagination.totalRecords;
          } else if (
            moreContracts.contracts.data.length % PAGE_SIZE_DEFAULT !== 0 &&
            state.contractsData.length >=
              moreContracts.contracts.pagination.totalRecords &&
            !onScroll
          )
            showErrorNotification(t("errors.errorNoNewRecord"));

          for (const index in moreContracts.contracts.data) {
            const isContain = state.contractsData.some(
              (contract: any) =>
                contract.id === moreContracts.contracts.data[index].id
            );
            if (!isContain)
              state.contractsData.push(moreContracts.contracts.data[index]);
          }

          state.contractsPagination.bookmark =
            moreContracts.contracts.pagination.bookmark || 0;

          state.isLoading = false;
        }
      }
    };

    const getInitListFolderContract = async () => {
      const statusStr = route.query.status
      let statusNum = filterStatus[statusStr as keyof typeof filterStatus] ?? -1
      const response = await loadFolderContract({ status: statusNum });
      if (response) {
        state.foldersData = response.folders.data;
        state.foldersPagination = response.folders.pagination;
        state.contractsPagination = response.contracts.pagination;
        state.totalFolder = response.folders.pagination.totalRecords ?? 0;
        state.totalContract = response.contracts.pagination.totalRecords ?? 0;
        if (state.totalFolder < PAGE_SIZE_DEFAULT) {
          state.pageContract = 1;
          state.contractsData = response.contracts.data;
        }
        state.currentFolderPath = response.folderPath ?? "";
        const listFolderPath = getListFolderFromPath(response.folderPath);
        if (route.params.folderPath) {
          const lastPathElement = listFolderPath.pop();
          if (lastPathElement)
            state.breadScrums.push({
              id: lastPathElement["id"] as string,
              text: lastPathElement["name"] as string,
              to: `/folder?folderId=${lastPathElement["id"]}&folderTitle=${lastPathElement["name"]}&status=${status}`,
            });
        } else {
          listFolderPath.map((path) => {
            state.breadScrums.push({
              id: path["id"] as string,
              text: path["name"] as string,
              to: `/folder?folderId=${path["id"]}&folderTitle=${path["name"]}&status=${status}`,
            });
          });
        }
      }
    };

    const loadInformationAndNavigationToEditContract = async (
      contract: any
    ) => {
      const accessCode = getAccessCode(contract.id);
      if (!canAccessWithoutAccessCode(contract) && !accessCode) {
        showAccessCodePopup(contract.id, { isEdit: true });
      } else {
        const contractInformation = (await getContractsFullInformation(
          contract.id,
          accessCode
        ).catch((err) => {
          showErrorNotification(err.data.message);
          reloadContractFolder({
            reloadContract: true,
          });
        })) as any;
        const localBasicContractInformation =
          mapLocalBasicInformation(contractInformation);
        const localDetailContractInformation = mapLocalDetailInformation(
          contractInformation.contractInformation ?? {}
        );
        const localFiles = mapCloudFileToLocalFile(
          contractInformation.contractFiles ?? []
        );
        const localSigners = mapLocalSignerFromCloudSigner(
          contractInformation.contractSigners ?? []
        );
        const localSecurity = mapLocalSecurityFromCloud(contractInformation);
        const localViewers = mapLocalViewerFromCloudViewer(
          contractInformation.contractViewers ?? []
        );

        const localNotification = mapLocalNotification(
          contractInformation.notification ?? {}
        );

        await Promise.all([
          store.dispatch(
            "template/setTemplateBasicInfo",
            localBasicContractInformation
          ),
          store.dispatch("template/setId", contract.id),
          store.dispatch(
            "template/setTemplateDetailInfo",
            localDetailContractInformation
          ),
          store.dispatch("template/setFiles", localFiles),
          store.dispatch("template/setIsTemplate", false),
          store.dispatch("template/setSigners", localSigners),
          store.dispatch("template/setSecurity", localSecurity),
          store.dispatch("template/setViewers", localViewers),
          store.dispatch(
            "template/setMessage",
            contractInformation.message ?? ""
          ),
          store.dispatch("template/setNotification", localNotification),

          store.dispatch(
            "template/setCustomFields",
            contractInformation.contractCustomFields ?? []
          ),
          store.dispatch(
            "template/setStatus",
            contractInformation.status ? contractInformation.status : 0
          ),
          store.dispatch(
            "template/setCountSigned",
            contractInformation.countSigned
              ? contractInformation.countSigned
              : 0
          ),
          store.dispatch("template/setFolder", {
            folderId: folderId ?? "",
            folderName: folderTitle ?? t("folder.allFolder"),
          }),
        ]);

        if (contractInformation.status === 0) {
          router.push({ name: "Contract", params: { canDelete: "false" } });
        } else
          router.push({
            name: "Contract",
            query: {
              isEdit: "true",
              onlyInformation: !canContractUpdate(
                contract.canUpdate,
                contract.countSigned,
                contract.status
              )
                ? "true"
                : "false",
              contractId: contract.id,
            },
            params: {
              status: status as string,
            },
          });
      }
    };

    const navigateToCreateContractPage = () => {
      if (folderId)
        store.dispatch("template/setFolder", {
          folderId: folderId,
          folderName: folderTitle || "",
        });
      router.push({
        name: "Contract",
      });
    };

    const onAddFolder = async ({ err, res }: { err: any; res: any }) => {
      if (err) {
        showErrorNotification(err.data.message);
      } else {
        await reloadContractFolder({ reloadFolder: true });
        showSuccessNotification(t("notification.createSuccess"));
      }
    };

    const onRenameFolderContract = async ({
      err,
      res,
    }: {
      err: any;
      res: any;
    }) => {
      if (err) {
        showErrorNotification(err.data.message);
      } else {
        if (state.renameSelectedPopup === RenamePopupList.RENAME_FOLDER) {
          await reloadContractFolder({ reloadFolder: true });
        } else if (
          state.renameSelectedPopup === RenamePopupList.RENAME_CONTRACT &&
          state.renameContractItem?.isFile
        ) {
          await reloadContractFolder({ reloadContract: true });
        }
        showSuccessNotification(t("notification.updateSuccess"));
      }
    };

    const onDeleteFolderContract = async ({
      err,
      res,
    }: {
      err: any;
      res: any;
    }) => {
      if (err) {
        if (err.status === 400) {
          if (state.confirmSelectedPopup === DeletePopupList.DELETE_FOLDER) {
            state.confirmSelectedPopup =
              DeletePopupList.DELETE_SIGNED_CONTRACT_ERROR;
          } else if (
            state.confirmSelectedPopup === DeletePopupList.DELETE_MULTIPLE
          ) {
            state.confirmSelectedPopup =
              DeletePopupList.DELETE_MULTIPLE_SIGNED_CONTRACT_ERROR;
          } else {
            showErrorNotification(err.data.message);
          }
        }
      } else {
        if (state.confirmSelectedPopup === DeletePopupList.DELETE_FOLDER) {
          await reloadContractFolder({ reloadFolder: true });
          state.selectedFolderList = state.selectedFolderList.filter(
            (item) => item.id !== state.deleteFolderItem.id
          );
        } else if (
          state.confirmSelectedPopup === DeletePopupList.DELETE_CONTRACT
        ) {
          await reloadContractFolder({ reloadContract: true });
          state.selectedContractList = state.selectedContractList.filter(
            (item) => item.id !== state.deleteContractItem.id
          );
        } else if (
          state.confirmSelectedPopup === DeletePopupList.DELETE_MULTIPLE
        ) {
          await reloadContractFolder({
            reloadContract: true,
            reloadFolder: true,
          });
          state.selectedContractList = [];
          state.selectedFolderList = [];
        }
        showSuccessNotification(t("notification.deleteSuccess"));
      }
    };

    const onCancelManyContract = async () => {
      state.selectedContractList = [];
      state.selectedFolderList = [];
    };

    const onMoveContractFolder = async ({
      err,
      res,
    }: {
      err?: any;
      res?: any;
    }) => {
      if (err) {
        showErrorNotification(err.data.message);
      } else {
        switch (state.moveSelectedPopup) {
          case MovePopupList.MOVE_FOLDER:
            await reloadContractFolder({ reloadFolder: true });
            state.selectedFolderList = state.selectedFolderList.filter(
              (item) => item.id !== state.moveFolderItem.id
            );
            break;
          case MovePopupList.MOVE_CONTRACT:
            await reloadContractFolder({ reloadContract: true });
            state.selectedContractList = state.selectedContractList.filter(
              (item) => item.id !== state.moveContractItem.id
            );
            break;
          case MovePopupList.MOVE_MULTIPLE:
            await reloadContractFolder({
              reloadContract: true,
              reloadFolder: true,
            });
            state.selectedContractList = [];
            state.selectedFolderList = [];
            break;
          default:
            break;
        }
        showSuccessNotification(t("notification.moveSuccess"));
      }
    };

    const onEditAccessFolder = async ({ err, res }: { err: any; res: any }) => {
      if (err) {
        showErrorNotification(err.data.message);
      } else {
        await reloadContractFolder({ reloadFolder: true });
        showSuccessNotification(t("notification.updateSuccess"));
      }
    };

    const onEditAccessContract = async ({
      err,
      res,
    }: {
      err: any;
      res: any;
    }) => {
      if (err) {
        showErrorNotification(err.data.message);
      } else {
        await reloadContractFolder({ reloadContract: true });
        showSuccessNotification(t("notification.updateSuccess"));
      }
    };

    const onSubmitTransferContract = async () => {
      await reloadContractFolder({ reloadContract: true });
      showSuccessNotification(t("notification.updateSuccess"));
    };

    const onTransferContract = () => {
      store.commit("popup/unsetPopup", "ContractTransfer");
    };

    const handleViewDetail = (contract: any) => {
      if (canAccessWithoutAccessCode(contract)) {
        if (contract.status >= 3)
          router.push({
            name: "SignDone",
            query: {
              contractId: contract.id,
            },
            params: {
              status: status as string,
            },
          });
        else
          router.push({
            name: "ContractDetailReadonly",
            query: {
              contractId: contract.id,
              isUser: "true",
            },
          });
      } else {
        showAccessCodePopup(contract.id);
        signState.value.isView = true;
      }
    };

    const handleViewDetailFile = (contract: any) => {
      router.push({
        name: "FileDetail",
        params: {
          "id": contract.id
        },
      });
    };

    const canAccessWithoutAccessCode = (contract: any) => {
      return contract.isCreator || !contract.requireAccessCode;
    };

    const signState = ref({
      contractTitle: "",
      contractId: "",
      isContract2FARequired: false,
      isView: false,
      isEdit: false,
    });
    const signXIDState = ref({
      xidFileList: [] as any,
      codeNumber: "",
      signFileId: "",
    });

    const { setFieldError } = useForm();

    const showAccessCodePopup = (
      contractId: string,
      options = { isEdit: false }
    ) => {
      signState.value.contractId = contractId;
      if (options.isEdit) signState.value.isEdit = true;
      const accessCode = getAccessCode(contractId);
      if (!accessCode) state.isShowAccessCodePopup = true;
      else onSignContract(contractId, accessCode);
    };

    const onSignContract = async (contractId: string, accessCode = "") => {
      try {
        const contract = (await getContractsFullInformation(
          contractId,
          accessCode
        )) as any;
        if (accessCode) saveAccessCode(contractId, accessCode);

        state.isShowAccessCodePopup = false;

        // const isAnyField = checkContractFields(contract);
        if (signState.value.isEdit)
          loadInformationAndNavigationToEditContract(contract);
        else if (signState.value.isView) {
          if (contract.status >= 3)
            router.push({
              name: "SignDone",
              query: {
                contractId: contract.id,
              },
            });
          else
            router.push({
              name: "ContractGuestReadonly",
              query: {
                contractId: contract.id,
                isUser: "true",
              },
            });
        } else navigateToSignDetails(contractId);
        // else signContract(contract);
      } catch (e: any) {
        setFieldError("accessCode", e.data.message);
      }
    };

    const checkContractFields = (contract: any) => {
      const files = contract.contractFiles.map((file: any) =>
        FileInfo.fromJson(file)
      ) as FileInfo[];
      return files.some((file: FileInfo) => file.hasFields());
    };

    const navigateToSignDetails = (contractId: string) => {
      router.push({
        name: "SignDetails",
        query: {
          contractId: contractId,
        },
        params: {
          status: status as string,
        },
      });
    };

    const signContract = (contract: any) => {
      signState.value = {
        contractId: contract.id,
        contractTitle: contract.title,
        isContract2FARequired: contract.is2FARequired,
        isView: false,
        isEdit: false,
      };
      if (isNeedToSign(contract.status)) {
        if (contract.needToSignByXID) {
          checkXIDInfo(contract);
        } else {
          store.commit("popup/setPopup", "RequestCode");
        }
      }
    };

    const checkXIDInfo = async (contract: any) => {
      const response = await checkXIDInformation({});
      if (response.xidInfo === "ok") {
        signXIDState.value.xidFileList = contract.contractFiles.map(
          (file: any) => ({
            ...file,
            isSign: file.signed || false,
            contractVars: [],
          })
        );
        store.commit("popup/setPopup", "SelectFileXID");
      } else {
        store.commit("popup/setPopup", "RequestLoginXID");
      }
    };

    const waitToSignFileByXID = ({ codeNumber = "", fileId = "" }) => {
      signXIDState.value.codeNumber = codeNumber;
      signXIDState.value.signFileId = fileId;
      store.commit("popup/unsetPopup", "SelectFileXID");
      store.commit("popup/setPopup", "SignPinXID");
    };

    const signFileSuccess = (fileId: string) => {
      const file =
        signXIDState.value.xidFileList.find(
          (item: any) => item.id === fileId
        ) || {};
      file.isSign = true;
      file.signed = true;

      const isContinuous =
        signXIDState.value.xidFileList.filter(
          (file: any) => file.isSign === false
        ).length !== 0;

      if (!isContinuous)
        router.push({
          name: "SignDone",
          query: {
            contractId: signState.value.contractId,
          },
        });
    };

    const onRejectContract = async (err: any) => {
      if (err) {
        showErrorNotification(err.data.message);
      } else {
        showSuccessNotification(t("notification.updateSuccess"));
        await reloadContractFolder({ reloadContract: true });
      }
    };

    const onChangeSort = async (sortValue: any) => {
      state.sortSelected = sortValue;
      await reloadContractFolder({ reloadFolder: true, reloadContract: true });
      isShownPopupState.isShownSortOption = false;
    };

    const onSubmitNotification = async (
      contractId: string,
      newNotificationValue: string
    ) => {
      const index = state.contractsData.findIndex(
        (item) => item.id === contractId
      );
      state.contractsData[index].contractNotificationId = newNotificationValue;
      store.commit("popup/unsetPopup", "EditFolderNotification");
    };

    const onDeleteNotificationSuccess = async () => {
      const index = state.contractsData.findIndex(
        (item) => item.contractNotificationId === state.deleteNotificationId
      );
      state.contractsData[index].contractNotificationId = "";
      state.isShowDeleteFolderNotificationPopup = false;
      showSuccessNotification(t("notification.deleteAlertNotifications"));
      store.commit("popup/unsetPopup", "EditFolderNotification");
      state.notificationContractItem = new Contract();
    };

    const onUploadContract = async (formData: FormData) => {
      await uploadContract(formData)
        .then(async (res) => {
          await reloadContractFolder({ reloadContract: true });
          showSuccessNotification(t("notification.fileUploadSuccess"));
        })
        .catch((err) => {
          showErrorNotification(err.data.message);
        });
    };

    const onResendEmail = async (contract: ContractI) => {
      await resendSignEmail(contract.id)
        .then(async () => {
          showSuccessNotification(t("notification.resendEmailSuccess"));
        })
        .catch((err) => {
          showErrorNotification(err.data.message);
        });
    };

    const DOCUMENT_CONDITION = {
      SIZE: 33554432,
      MAX_FILES: 5,
      FILE_TYPE: ".docx,.pdf,.xlsx,.DOCX,.PDF,.XLSX",
    };

    const setFiles = (e: any) => {
      const uploadFiles = e.target.files || e.dataTransfer.files;
      if (uploadFiles.length > 0) {
        const formData = new FormData();
        if (uploadFiles.length > DOCUMENT_CONDITION.MAX_FILES) {
          showErrorNotification(
            t("errors.errorMaxFiles", {
              maxFiles: DOCUMENT_CONDITION.MAX_FILES,
            })
          );
          return;
        }
        for (let i = 0; i < uploadFiles.length; i++) {
          const fileExtension = getFileExtension(uploadFiles[i].name);
          if (!DOCUMENT_CONDITION.FILE_TYPE.includes(fileExtension)) {
            showErrorNotification(
              t("errors.errorFileExtension", { fileExtension: fileExtension })
            );
            return;
          }
          if (uploadFiles[i].size > DOCUMENT_CONDITION.SIZE) {
            showErrorNotification(t("errors.errorOversizeFile"));
            e.target.value = "";
            return;
          }
          formData.append("files", uploadFiles[i]);
        }
        if (folderId) {
          formData.append("folderId", folderId as string);
        }
        onUploadContract(formData);
      }
    };

    const getDropInfo = () => {
      const isFolder = state.foldersData.some(
        (folder: any) => folder.id === state.draggedItem
      );
      // If dragged item in selected List => Will move many
      const isMoveMany = [
        ...state.selectedFolderList,
        ...state.selectedContractList,
      ].some((item: any) => item.id === state.draggedItem);

      // Change moveSelectedPopup state to run onSuccessError correct
      if (isMoveMany) {
        state.moveSelectedPopup = MovePopupList.MOVE_MULTIPLE;
      } else {
        if (isFolder) {
          state.moveSelectedPopup = MovePopupList.MOVE_FOLDER;
        } else {
          state.moveSelectedPopup = MovePopupList.MOVE_CONTRACT;
        }
      }

      return {
        isFolder,
        isMoveMany,
        selectedFolders: state.selectedFolderList,
        selectedContracts: state.selectedContractList,
        onSuccessError: onMoveContractFolder,
      };
    };

    onBeforeMount(async () => {
      await getInitListFolderContract();
    });

    onMounted(async () => {
      const previousPath = getListFolderFromPath(
        (route.params.folderPath as string) ?? ""
      );
      previousPath.map((path) => {
        state.breadScrums.push({
          id: path["id"] as string,
          text: path["name"] as string,
          to: `/folder?folderId=${path["id"]}&folderTitle=${path["name"]}&status=${status}`,
        });
      });
      if (props.withSuccess) {
        showSuccessNotification(props.withSuccess);
      } else if (props.withError) {
        showErrorNotification(props.withError);
      }

      if (status) {
        state.filterStatusSelectedName = status as string;
        state.filterStatusSelected = filterStatus[status as keyof typeof filterStatus] ?? -1
      }

      window.addEventListener("keydown", (e) => {
        if (e.key === "Shift" && !state.isShiftPress) {
          state.isShiftPress = true;
        }
      });

      window.addEventListener("keyup", (e) => {
        if (e.key === "Shift" && state.isShiftPress) {
          state.isShiftPress = false;
        }
      });
    });

    return {
      ...toRefs(state),
      ...toRefs(isShownPopupState),
      UserRole,
      folderId,
      folderTitle,
      isMobile,
      totalItems,
      loadMore,
      onChangeSort,
      onAddFolder,
      onRenameFolderContract,
      onMoveContractFolder,
      onSubmitTransferContract,
      onTransferContract,
      onDeleteFolderContract,
      onEditAccessFolder,
      onEditAccessContract,
      onCancelManyContract,
      onUploadContract,
      onSignContract,
      onRejectContract,
      loadInformationAndNavigationToEditContract,
      navigateToCreateContractPage,
      getListFolderFromPath,
      onSubmitNotification,
      createContractFromTemplate,
      onDeleteNotificationSuccess,
      accountInfo,
      signState,
      signXIDState,
      waitToSignFileByXID,
      signFileSuccess,
      showAccessCodePopup,
      handleViewDetail,
      handleViewDetailFile,
      setFiles,
      onDragItemStart,
      onDragItemEnd,
      onDragItemEnter,
      getDropInfo,
      onDropItem,
      onResendEmail,
    };
  },
  computed: {
    enableMultipleDelete(): boolean {
      return (
        !this.selectedContractList.some(
          (item) =>
            !canContractDelete(item.canDelete, item.countSigned, item.status)
        ) && !this.selectedFolderList.some((item) => !item.canDelete)
      );
    },
    enableMultipleMove(): boolean {
      return (
        !this.selectedContractList.some((item) => !item.canUpdate) &&
        !this.selectedFolderList.some((item) => !item.canUpdate)
      );
    },
    contractFilterList() {
      let filterTmp = this.contractsData as any;
      if (this.filterStatusSelected !== -1) {
        filterTmp = this.contractsData.filter(
          (contract: any) =>
            contract.status === this.filterStatusSelected && !contract.isFile
        );
      }
      return filterTmp;
    },
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    handleDeselect(selectItem: any) {
      if (selectItem.isFolder) {
        this.selectedFolderList = this.selectedFolderList.filter(
          (item: any) => item.id !== selectItem.id
        );
      } else {
        this.selectedContractList = this.selectedContractList.filter(
          (item: any) => item.id !== selectItem.id
        );
      }
    },

    handleShiftSelect(selectItem: any, isSelected: boolean) {
      const selectedList = selectItem.isFolder
        ? this.selectedFolderList
        : this.selectedContractList;
      const index = selectedList.findIndex(
        (item: any) => item.id === selectItem.id
      );
      if (index === -1 && isSelected) {
        selectedList.push(selectItem);
        const [min, max] = this.getSelectedMaxMinIndex();
        const datas = [...this.foldersData, ...this.contractsData];
        const selectedIds = [
          ...this.selectedFolderList,
          ...this.selectedContractList,
        ].map((item) => item.id);
        for (let index = min; index <= max; index++) {
          if (!selectedIds.includes(datas[index].id)) {
            if (index < this.foldersData.length) {
              this.selectedFolderList = [
                ...this.selectedFolderList,
                datas[index] as FolderI,
              ];
            } else {
              this.selectedContractList = [
                ...this.selectedContractList,
                datas[index] as ContractI,
              ];
            }
          }
        }
      }
      if (index !== -1 && !isSelected) {
        this.handleDeselect(selectItem);
      }
    },

    handleSelect(selectItem: any, isSelected: boolean) {
      const selectedList = selectItem.isFolder
        ? this.selectedFolderList
        : this.selectedContractList;
      const index = selectedList.findIndex(
        (item: any) => item.id === selectItem.id
      );
      if (index === -1 && isSelected) {
        selectedList.push(selectItem);
      }
      if (index !== -1 && !isSelected) {
        this.handleDeselect(selectItem);
      }
    },

    onSelect(item: any, isSelected: boolean) {
      const totalSelect =
        this.selectedFolderList.length + this.selectedContractList.length;
      if (this.isShiftPress && totalSelect > 0) {
        this.handleShiftSelect(item, isSelected);
      } else {
        this.handleSelect(item, isSelected);
      }
    },

    getSelectedMaxMinIndex(): any[] {
      const selectedIds = [
        ...this.selectedFolderList,
        ...this.selectedContractList,
      ].map((item) => item.id);
      const datas = [...this.foldersData, ...this.contractsData];
      let result: any[] = []; // [Min,Max]
      let firstExact = false;
      datas.forEach((item, index) => {
        if (selectedIds.includes(item.id)) {
          result = firstExact ? [result[0], index] : [index, index];
          firstExact = true;
        }
      });
      return result;
    },

    onShowMenuOption(id: string) {
      this.showMenuOptionId = this.showMenuOptionId === id ? "" : id;
    },

    handleAddFolder(): void {
      this.setPopup("FolderAdd");
    },

    handleRenameFolder(folderItem: FolderI): void {
      this.renameFolderItem = new Folder(folderItem);
      this.showMenuOptionId = "";
      this.renameSelectedPopup = RenamePopupList.RENAME_FOLDER;
      this.setPopup("FolderRename");
    },

    handleRenameFile(fileItem: ContractI): void {
      this.renameContractItem = new Contract(fileItem);
      this.showMenuOptionId = "";
      this.renameSelectedPopup = RenamePopupList.RENAME_CONTRACT;
      this.setPopup("FolderRename");
    },

    handleEditAccessFolder(folderItem: FolderI): void {
      this.editAccessFolderItem = new Folder(folderItem);
      this.showMenuOptionId = "";
      this.setPopup("FolderEditAccess");
    },

    handleMoveFolder(folderItem: FolderI): void {
      this.moveFolderItem = new Folder(folderItem);
      this.showMenuOptionId = "";
      this.moveSelectedPopup = MovePopupList.MOVE_FOLDER;
      this.setPopup("FolderContractMove");
    },
    handleEditContractInformation(contractItem: ContractI): void {
      this.editInforContract = new Contract(contractItem);
      this.setPopup("ContractInformation");
    },
    handleMoveContract(contractItem: ContractI): void {
      this.moveContractItem = new Contract(contractItem);
      this.showMenuOptionId = "";
      this.moveSelectedPopup = MovePopupList.MOVE_CONTRACT;
      this.setPopup("FolderContractMove");
    },

    handleMoveMultipleContract(): void {
      this.moveSelectedPopup = MovePopupList.MOVE_MULTIPLE;
      this.setPopup("FolderContractMove");
    },

    handleDeleteFolder(folderItem: FolderI): void {
      this.deleteFolderItem = new Folder(folderItem);
      this.showMenuOptionId = "";
      this.confirmSelectedPopup = DeletePopupList.DELETE_FOLDER;
      this.setPopup("ConfirmDelete");
    },

    handleDeleteContract(contractItem: ContractI): void {
      this.deleteContractItem = new Contract(contractItem);
      this.showMenuOptionId = "";
      this.confirmSelectedPopup = DeletePopupList.DELETE_CONTRACT;
      this.setPopup("ConfirmDelete");
    },

    handleDeleteMultiple() {
      this.showMenuOptionId = "";
      this.confirmSelectedPopup = DeletePopupList.DELETE_MULTIPLE;
      this.setPopup("ConfirmDelete");
    },

    handleAlertNotification(contractItem: ContractI): void {
      this.notificationContractItem = new Contract(contractItem);
      this.showMenuOptionId = "";
      this.setPopup("EditFolderNotification");
    },

    onDeleteNotification(notificationID: string) {
      this.deleteNotificationId = notificationID;
      this.isShowDeleteFolderNotificationPopup = true;
      this.isShowEditFolderNotificationPopup = false;
    },

    onHideDeleteFolderNotificationPopup() {
      this.isShowDeleteFolderNotificationPopup = false;
      this.isShowEditFolderNotificationPopup = true;
    },

    viewTransfer(contractItem: ContractI) {
      this.transferContractItem = new Contract(contractItem);
      this.setPopup("ContractTransfer");
    },

    handleEditAccessContract(contractItem: ContractI) {
      this.editAccessContractItem = new Contract(contractItem);
      this.showMenuOptionId = "";
      this.setPopup("ContractEditAccess");
    },

    handleRejectContract(contractItem: ContractI) {
      this.rejectContractItem = new Contract(contractItem);
      this.showMenuOptionId = "";
      this.setPopup("RejectSign");
    },

    handleClickUpload() {
      const inputFileUpload = this.$refs.inputFileUpload as HTMLElement;
      inputFileUpload.click();
    },

    hideMenuOption(e: any): void {
      if (e.target.className != "icon selected" && e.target.alt != "more") {
        this.showMenuOptionId = "";
      }
    },

    changeFilterStatus(id: any) {
      this.filterStatusSelected = id;
      var newQuery = "";
      switch (id) {
        case CONTRACT_STATUS.DRAFT:
          newQuery = "draft";
          break;
        case CONTRACT_STATUS.CONFIRM_REQUIRED:
          newQuery = "confirm-request";
          break;
        case CONTRACT_STATUS.CHECKING:
          newQuery = "checking";
          break;
        case CONTRACT_STATUS.CONCLUDED:
          newQuery = "concluded";
          break;
        case CONTRACT_STATUS.REJECTED:
          newQuery = "rejected";
          break;
        default:
          newQuery = "";
          break;
      }

      const query = Object.assign({}, this.$route.query);
      query["status"] = newQuery;
      this.$router.push({ query: query });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.unsetPopup("SelectFileXID");
    this.unsetPopup("SignPinXID");
    if (to.fullPath.includes("/sign/details") && from.params.isRejectSuccess) {
      next({
        name: "ErrorPage",
        query: {
          error: this.$t("errors.errorBackToSignAgain"),
        },
      });
    } else next();
  },
});

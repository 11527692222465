
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
  inject,
  ref,
} from "vue";
import {
  RepositoryFactory,
  FolderRepository,
  ContractRepository,
} from "@/lib/https";
import FolderPath from "@/components/atomics/FolderPath.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import Popup from "@/components/popups/Popup.vue";
import { mapMutations, useStore } from "vuex";
import { getListFolderFromPath } from "@/lib/utility/common";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "FolderContractMovePopup",
  components: {
    FolderPath,
    FlatButton,
    Popup,
  },
  props: {
    title: { type: String, required: true },
    folderItem: { type: Object },
    contractItem: { type: Object },
    selectedContractList: { type: Array },
    selectedFolderList: { type: Array },

    isTemplateFolder: { type: Boolean, default: false },
    folderId: { type: String, default: "" },
    moveSelectedPopup: { type: Number, required: true },
    isTemplate: { type: Boolean, default: false },
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      groupPath: [] as any,
      hoverItemIndex: -1,
      folders: [],
      currentFolder: {} as any,
      isLoading: false,
    });

    const { t } = useI18n();

    const isMobile = inject("isMobile");
    const MovePopupList = inject("MovePopupList") as any;

    const searchKeyword = ref("");
    const searchKeywordTextField = ref("");

    const { getListFolder, moveFolder } =
      RepositoryFactory.getRepository<FolderRepository>(FolderRepository);
    const { moveContract, moveManyContractFolder } =
      RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

    const getListFolderData = async (folderId = "") => {
      state.folders.splice(0);
      state.groupPath.splice(0);
      searchKeyword.value = "";
      searchKeywordTextField.value = "";
      if (folderId === "received-folder") {
        folderId = "";
      }
      const response = await getListFolder({
        folderId,
        isTemplateFolder: props.isTemplateFolder,
      });
      if (response) {
        if (props.moveSelectedPopup === MovePopupList.MOVE_FOLDER) {
          state.folders = response.data.filter(
            (folder: any) => folder.id != props.folderItem?.id
          );
        } else if (props.moveSelectedPopup === MovePopupList.MOVE_CONTRACT) {
          state.folders = response.data;
        } else if (props.moveSelectedPopup === MovePopupList.MOVE_MULTIPLE) {
          state.folders = response.data.filter(
            (folder: any) =>
              !props.selectedFolderList?.some(
                (item: any) => item.id === folder.id
              )
          );
        }
        const paths = getListFolderFromPath(response.pagination.folderPath);
        state.groupPath = [...state.groupPath, ...paths];
        state.currentFolder = paths.pop();
      }
    };

    const clickRootPath = async () => {
      state.groupPath = [];
      state.folders = [];
      await getListFolderData();
    };

    const clickFolderPath = async (folder: any) => {
      state.folders = [];
      const index = state.groupPath.indexOf(folder);
      if (index > -1) {
        state.groupPath.splice(index + 1, state.groupPath.length);
      }
      await getListFolderData(folder.id);
    };

    const resetPopup = () => {
      state.groupPath = [] as any;
      state.hoverItemIndex = -1;
      state.folders = [];
      searchKeyword.value = "";
      searchKeywordTextField.value = "";
    };

    watch(
      () => store.state.popup.FolderContractMove,
      (val: any) => {
        if (val) {
          resetPopup();
          getListFolderData(props.folderId);
        }
      }
    );

    let delay: ReturnType<typeof setTimeout>;

    const search = async () => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        state.folders.splice(0);
        state.groupPath.splice(0);
        const response = await getListFolder({
          keyword: searchKeyword.value,
          isTemplateFolder: props.isTemplateFolder,
        });
        if (response) {
          state.folders = response.data;
          const paths = getListFolderFromPath(response.pagination.folderPath);
          state.groupPath = [...state.groupPath, ...paths];
          state.currentFolder = paths.pop();
        }
      }, 300);
    };

    const onMove = async () => {
      try {
        let res: any;
        let data: any;
        let ids = [];
        let folderIds = [];

        switch (props.moveSelectedPopup) {
          case MovePopupList.MOVE_FOLDER:
            data = {
              id: props.folderItem?.id,
              parentFolderId: state.currentFolder?.id || "",
              isTemplateFolder: props.isTemplateFolder,
            };
            res = await moveFolder(data);
            break;

          case MovePopupList.MOVE_CONTRACT:
            data = {
              id: props.contractItem?.id,
              folderId: state.currentFolder?.id || "",
            };
            res = await moveContract(data);
            break;

          case MovePopupList.MOVE_MULTIPLE:
            ids = props.selectedContractList?.map((item: any) => item.id) ?? [];
            folderIds =
              props.selectedFolderList?.map((item: any) => item.id) ?? [];
            data = {
              ids,
              folderIds,
              folderId: state.currentFolder?.id,
              isTemplateFolder: props.isTemplateFolder,
            };
            res = await moveManyContractFolder(data);
            break;
          default:
            break;
        }
        context.emit("onSubmitMoveContractFolder", { res });
      } catch (err) {
        context.emit("onSubmitMoveContractFolder", { err });
      }
      store.commit("popup/unsetPopup", "FolderContractMove");
    };

    return {
      ...toRefs(state),
      popupState: computed(() => store.state.popup.FolderContractMove),
      isMobile,
      resetPopup,
      getListFolderData,
      clickRootPath,
      clickFolderPath,
      onMove,
      search,
      searchKeyword,
      searchKeywordTextField,
      enabledMoveBtn: computed(() =>
        Boolean(
          state.currentFolder || state.currentFolder?.id == null
        )
      ),
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    hidePopup(): void {
      this.unsetPopup("FolderContractMove");
      this.resetPopup();
    },

    onHover(index: number): void {
      this.hoverItemIndex = index;
    },

    onLeave(): void {
      this.hoverItemIndex = -1;
    },

    handleClickFolder(folder: any): void {
      this.groupPath.push(folder);
      this.getListFolderData(folder.id);
    },

    onInput(value: any) {
      this.searchKeyword =
        this.searchKeywordTextField +
        (value.isComposing ? value?.data ?? "" : "");
      this.search();
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */


import { defineComponent, reactive, toRefs, inject, watch } from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import TableSelect from "@/components/atomics/TableSelect.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import { useStore, mapMutations } from "vuex";
import { computed } from "vue";
import { FolderRepository, RepositoryFactory } from "@/lib/https";
import { Folder, MemberPermissionsI, GroupPermissionsI } from "@/models/Folder";
import Popup from "@/components/popups/Popup.vue";
import { useRoute } from "vue-router";
import { useForm } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import { ReadWriteRole } from "@/lib/utility/permission";

export default defineComponent({
  name: "FolderAddPopup",
  components: {
    ValidationTextField,
    FlatButton,
    ToggleCheckbox,
    TableSelect,
    Popup,
    MenuOptionDropdown,
  },
  props: {
    title: { type: String, required: true },
    isTemplateFolder: { type: Boolean, default: false },
  },
  setup(props, context) {
    const isMobile = inject("isMobile");
    const store = useStore();
    const FolderPermissions = inject("FolderPermissions");
    const state = reactive({
      isOverflow: false,
      editName: "",
      isShowTableSelect: false,
      users: [],
      groups: [],
      selectedUserGroup: [] as (MemberPermissionsI | GroupPermissionsI)[],
      selectedPermission: ReadWriteRole.READ_WRITE,
      permissions: FolderPermissions,
      isLoading: false,
      emptyError: false,
    });
    const { getUsersAndGroups, createFolder } =
      RepositoryFactory.getRepository<FolderRepository>(FolderRepository);
    const addTagGroupUser = (lst: any, isUser = true) => {
      return lst.map((item: any) => ({
        ...item,
        userId: isUser ? item.id : "",
        groupId: !isUser ? item.id : "",
      }));
    };

    watch(
      () => store.state.popup.FolderAdd,
      async (val) => {
        if (val) {
          const response = await getUsersAndGroups({});
          if (response) {
            state.users = addTagGroupUser(response.users.data);
            state.groups = addTagGroupUser(response.groups.data, false);
          }
        }
      }
    );

    const resetPopup = () => {
      state.editName = "";
      state.isShowTableSelect = false;
    };

    const route = useRoute();
    const form = useForm();
    const { formHandle } = useFormHandler(form, async () => {
      if (state.isShowTableSelect && state.selectedUserGroup.length === 0) {
        // form.setErrors({
        //   selectedUserGroup:
        //     "少なくとも1つのメンバーまたはグループを選択します", // Select at least one member or group
        // });
        state.emptyError = true;
        return;
      }
      await createNewFolder();
    });

    const createNewFolderBtn = async function () {
      await formHandle();
    };

    const hidePopup = () => {
      store.commit("popup/unsetPopup", "FolderAdd");
      resetPopup();
    };

    const createNewFolder = async function () {
      const { folderId } = route.query;
      const selectedUser: any = [];
      const selectedGroup: any = [];
      state.selectedUserGroup.forEach((item: any) => {
        item.role = Number(state.selectedPermission);
        if (item.userId) {
          selectedUser.push(item);
        } else if (item.groupId) {
          selectedGroup.push(item);
        }
      });
      const payload = new Folder({
        name: state.editName,
        parentFolderId: folderId as string,
        folderMemberPermissions: selectedUser,
        folderGroupPermissions: selectedGroup,
        isTemplateFolder: props.isTemplateFolder,
        isInheritedPermission: state.selectedUserGroup.length === 0,
      });
      await createFolder(payload)
        .then(async (res: any) => {
          context.emit("onSubmitAddFolder", { res });
        })
        .catch((err: any) => {
          context.emit("onSubmitAddFolder", { err });
        });
      store.commit("popup/unsetPopup", "FolderAdd");
      resetPopup();
    };

    let delay: ReturnType<typeof setTimeout>;
    const onSearchChange = (searchKeyword: any) => {
      state.emptyError = false;
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        const response = await getUsersAndGroups({
          keyword: searchKeyword,
        });
        if (response) {
          state.users = addTagGroupUser(response.users.data);
          state.groups = addTagGroupUser(response.groups.data, false);
        }
      }, 300);
    };

    return {
      ...toRefs(state),
      popupState: computed(() => store.state.popup.FolderAdd),
      isMobile,
      form,
      hidePopup,
      onSearchChange,
      createNewFolderBtn,
      resetPopup,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    showTableSelect(value: boolean): void {
      this.isShowTableSelect = value;
    },

    handleSelectUserGroup(selectedItems: any) {
      this.selectedUserGroup = selectedItems;
      this.form.setErrors({
        selectedUserGroup: undefined,
      });
    },

    handleChangePermission(id: any) {
      this.selectedPermission = id;
    },

    onUpdateLayoutTableSelect() {
      const height = window.innerHeight;
      const popupContainer = this.$refs.popup as HTMLElement;
      const clientHieght = popupContainer?.clientHeight as number;
      if (clientHieght >= height - 210) {
        this.isOverflow = true;
      } else {
        this.isOverflow = false;
      }
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */

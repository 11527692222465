
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
  inject,
  onMounted
} from "vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import TableSelect from "@/components/atomics/TableSelect.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import Popup from "@/components/popups/Popup.vue";
import { RepositoryFactory, FolderRepository } from "@/lib/https";
import {
  MemberPermissionsI,
  GroupPermissionsI,
  FolderI,
} from "@/models/Folder";
import { mapMutations, useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ReadWriteRole, isReadWrite, isOwner } from "@/lib/utility/permission";

export default defineComponent({
  name: "FolderEditMemberPopup",
  components: {
    Dropdown,
    FlatButton,
    TableSelect,
    Popup,
  },
  props: {
    title: { type: String, required: true },
    folderItem: { type: Object, requried: true },
  },
  setup(props, context) {
    const store = useStore();
    const FolderPermissions = inject("FolderPermissions");
    const { t } = useI18n();

    const state = reactive({
      selectedPermission: ReadWriteRole.READ_WRITE,
      permissions: FolderPermissions,
      readOnly: [{ id: 2, name: t("folder.readOnly") }],
      userPermissions: [] as any[],
      groupPermissions: [] as any[],
      userSearch: [],
      groupSearch: [],
      selectedUserGroup: [] as (MemberPermissionsI | GroupPermissionsI)[],
      delay: "",
      avatarBaseUrl: process.env.VUE_APP_API_CLOUD_URL,
      isLoading: false,
      updateRoleData: [] as any[],
      deleteRoleData: [] as any[],
    });

    const folderItem = props.folderItem as FolderI;

    const {
      getUsersAndGroups,
      getFolderPermissions,
      updateFolderGroupMemeberPermission,
      deleteFolderGroupMemeberPermission,
      addFolderMemberGroupPermission,
    } = RepositoryFactory.getRepository<FolderRepository>(FolderRepository);

    const addTagGroupUser = (lst: any, isUser = true) => {
      return lst.map((item: any) => ({
        ...item,
        userId: isUser ? item.id : "",
        groupId: !isUser ? item.id : "",
      }));
    };

    const getLastestPopupData = async () => {
      const folderItem = props.folderItem as FolderI;
      if(!folderItem.canUpdate) {
        state.selectedPermission = ReadWriteRole.READ_ONLY;
      }
      const response = await Promise.all([
        getFolderPermissions(folderItem.id),
        getUsersAndGroups({ folderId: folderItem.id }),
      ]);
      if (response) {
        const [folderPermissionList, userGroupList] = response;
        if (folderPermissionList && userGroupList) {
          state.userPermissions = addTagGroupUser(folderPermissionList.users);
          state.groupPermissions = addTagGroupUser(
            folderPermissionList.groups,
            false
          );
          state.userSearch = addTagGroupUser(userGroupList.users.data);
          state.groupSearch = addTagGroupUser(userGroupList.groups.data, false);
        }
      }
    };

    watch(
      () => props.folderItem,
      async () => {
        getLastestPopupData();
      }
    );

    const onAddFolderPermission = async () => {
      const editRolePromise: Promise<any>[] = [];
      if (state.selectedUserGroup.length > 0) {
        state.selectedUserGroup.forEach((item: any) => {
          item.role = Number(state.selectedPermission);
          item.folderId = props.folderItem?.id;
          editRolePromise.push(addFolderMemberGroupPermission(item));
        });
      }

      if (state.updateRoleData.length > 0) {
        state.updateRoleData.forEach((item: any) => {
          editRolePromise.push(updateFolderGroupMemeberPermission(item));
        });
      }

      if (state.deleteRoleData.length > 0) {
        state.deleteRoleData.forEach((item: any) => {
          editRolePromise.push(
            deleteFolderGroupMemeberPermission(item.folderItem, item.item)
          );
        });
      }

      if (editRolePromise.length > 0) {
        await Promise.all(editRolePromise)
          .then((res) => {
            context.emit("onSubmitEditAccessFolder", res);
            state.selectedPermission = ReadWriteRole.READ_WRITE;
            resetPopup();
          })
          .catch((err) => {
            context.emit("onSubmitEditAccessFolder", err);
          });
        store.commit("popup/unsetPopup", "FolderEditAccess");
      } else {
        store.commit("popup/unsetPopup", "FolderEditAccess");
      }
    };

    const resetPopup = () => {
      state.selectedPermission = ReadWriteRole.READ_WRITE;
      state.selectedUserGroup = [];
      state.userPermissions = [];
      state.groupPermissions = [];
      state.userSearch = [];
      state.groupSearch = [];
      state.updateRoleData = [];
      state.deleteRoleData = [];
    };

    const onUpdateRole = async (roleId: number, item: any) => {
      const roleItem = state.updateRoleData.find((data) => data.id === item.id);
      if (roleItem) {
        if (item.role === roleId) {
          state.updateRoleData = state.updateRoleData.filter(
            (data) => data.id !== item.id
          );
        } else {
          roleItem.role = roleId;
        }
      } else {
        state.updateRoleData.push({
          ...item,
          folderId: props.folderItem?.id,
          role: roleId,
        });
      }
    };

    const onDeletePermission = async (item: any) => {
      state.updateRoleData = state.updateRoleData.filter(
        (data) => data.id !== item.id
      );
      state.userPermissions = state.userPermissions.filter(
        (data) => data.id !== item.id
      );
      state.groupPermissions = state.groupPermissions.filter(
        (data) => data.id !== item.id
      );
      state.deleteRoleData.push({
        item,
        folderItem: props.folderItem,
      });
    };

    let delay: ReturnType<typeof setTimeout>;
    const onSearchChange = (searchKeyword: any) => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        const folderItem = props.folderItem as FolderI;
        const response = await getUsersAndGroups({
          folderId: folderItem.id,
          keyword: searchKeyword,
        });
        if (response) {
          state.userSearch = addTagGroupUser(response.users.data);
          state.groupSearch = addTagGroupUser(response.groups.data, false);
        }
      }, 300);
    };

    const scrollToBottom = (value: boolean) => {
      if (!value) return;
      let item = document.querySelector(".popup-container");
      item?.scrollTo({
        top: item.scrollHeight,
        behavior: "smooth",
      });
    };

    return {
      ...toRefs(state),
      popupState: computed(() => store.state.popup.FolderEditAccess),
      onSearchChange,
      onAddFolderPermission,
      onUpdateRole,
      onDeletePermission,
      resetPopup,
      scrollToBottom,
      isReadWrite,
      ReadWriteRole,
      isOwner,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),
    hidePopup(): void {
      this.unsetPopup("FolderEditAccess");
      this.resetPopup();
    },

    handleChangePermission(id: any) {
      this.selectedPermission = id;
    },

    handleSelectUserGroup(selectedItems: any) {
      this.selectedUserGroup = selectedItems;
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */

import {
	RepositoryFactory,
	FolderRepository,
	ContractRepository,
} from "@/lib/https";

const { moveFolder } =
	RepositoryFactory.getRepository<FolderRepository>(FolderRepository);
const { moveContract, moveManyContractFolder } =
	RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

export default function useFolderContractHandler(state: any): {
	// Drag drop to move
	onDragItemStart: (folderId: string) => void;
	onDragItemEnd: () => void;
	onDragItemEnter: (folderId: string) => void;
	onDropItem: (folderId: string, getDropInfo: CallableFunction) => void
} {
	const onDragItemStart = (folderId: string) => {
		state.draggedItem = folderId;
	};

	const onDragItemEnd = () => {
		state.draggedItem = "";
		state.dragEnterItem = "";
	}

	const onDragItemEnter = (folderId: string) => {
		state.dragEnterItem = folderId;
	}

	const onDropItem = async (folderId: string, getDropInfo: CallableFunction) => {
		const { isFolder, isMoveMany, selectedFolders, selectedContracts, onSuccessError, isTemplateFolder } = getDropInfo();
		try {
			let res;
			if (isMoveMany) {
				// Check drop item in selected list => Can't move
				const isDropInList = [
					...selectedFolders,
					...selectedContracts,
				].some((item: any) => item.id === folderId);
				if (!isDropInList) {
					res = await moveManyContractFolder({
						ids: selectedContracts?.map((item: any) => item.id) ?? [],
						folderIds: selectedFolders?.map((item: any) => item.id) ?? [],
						folderId,
						isTemplateFolder: isTemplateFolder ?? false
					})
					onSuccessError({ res });
				}
			} else {
				if (
					folderId === state.dragEnterItem &&
					folderId !== state.draggedItem
				) {
					res = isFolder ? await moveFolder({
						id: state.draggedItem,
						parentFolderId: folderId,
					}) : await moveContract({
						id: state.draggedItem,
						folderId,
					});
					onSuccessError({ res });
				}
			}
		}
		catch (err: any) {
			onSuccessError({ err });
		}
	};

	return {
		onDragItemStart,
		onDragItemEnd,
		onDragItemEnter,
		onDropItem
	}
}

import { computed, defineComponent, ref } from "vue";

import FlatButton from "@/components/atomics/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import Popup from "@/components/popups/Popup.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "AccessCodePopup",
  components: {
    FlatButton,
    ValidationTextField,
    Popup,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const code = ref("");

    const store = useStore();
    const onInputAccessCode = async () => {
      emit("onInputAccessCode", code.value);
    };

    const hide = () => {
      code.value = "";
      emit("onHide");
    };

    return { code, hide, onInputAccessCode };
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */

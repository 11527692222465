
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  inject,
  watch,
} from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import XIDButton from "@/components/atomics/XIDButton.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import { formatDate } from "@/lib/utility/common";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";
import { ref } from "vue";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";
import { ContractStatus, hasPermission, isNeedToSign, isWaitingForSignature, canContractDelete, isReceivedFolder } from "@/lib/utility/permission";

export default defineComponent({
  name: "ContractItem",
  components: {
    TextButton,
    FlatButton,
    XIDButton,
    MenuOptionDropdown,
    Vue3Popper,
  },
  props: {
    folderId: {type: String, default: ""},
    contract: { type: Object, required: true },
    index: { type: Number, required: true },
    selectedContractList: { type: Array },
    showMenuOptionId: { type: String, default: "" },
  },
  emits: [
    "onSign",
    "onSelect",
    "onRenameFile",
    "onMoveContract",
    "onRejectContract",
    "onDeleteContract",
    "onShowMenuOption",
    "onTransferContract",
    "onAlertNotification",
    "onEditAccessContract",
    "onViewDetailFile",
    "onViewDetail",
    "onResendEmail",
    "onEditInformationContract",
  ],
  setup(props) {
    const state = reactive({
      isHover: false,
      isSelected: false,
    });
    
    const isPermitted = hasPermission(props.contract.role)
    const canAlertOrResendEmail = computed(() => {
      return props.contract.canUpdate && isWaitingForSignature(props.contract.status)
    });
    const isSigner = computed(() => {
      return isNeedToSign(props.contract.status) || props.contract.needToSignByXID
    });
    const canDelete = computed(() => {
      return canContractDelete(props.contract.canDelete, props.contract.countSigned, props.contract.status)
    });
    const isReceived = isReceivedFolder(props.folderId)

    const isMobile = inject("isMobile");
    const router = useRouter();
    const store = useStore();
    const { getAccessCode } = useSignFunctions();
    const tooltipIndex = ref(-1);

    const get_status_class = computed(() => {
      const status = props.contract?.status;
      switch (status) {
        case ContractStatus.DRAFT:
          return "draft";
        case ContractStatus.NEED_TO_SIGN:
          return "confirm-request";
        case ContractStatus.WAIT_OTHER_SIGNER_SIGN:
          return "checking";
        case ContractStatus.SIGNED:
          return "concluded";
        case ContractStatus.REJECTED:
          return "rejected";
        default:
          return "";
      }
    });

    watch(
      () => props.selectedContractList,
      (selectedContractList) => {
        state.isSelected =
          selectedContractList?.some(
            (item: any) => item.id === props.contract.id
          ) ?? false;
      }
    );

    return {
      ...toRefs(state),
      isMobile,
      get_status_class,
      formatDate,
      getFilePathFromUrl,
      tooltipIndex,
      ContractStatus,
      isPermitted,
      canAlertOrResendEmail,
      isSigner,
      canDelete,
      isReceived,
    };
  },
  methods: {
    handleSelectContract(item: any) {
      this.isHover = false;
      this.$emit("onSelect", item, this.isSelected);
    },
    handleClickIcon(item: any) {
      this.isSelected = true;
      this.handleSelectContract(item);
    },
    handleShowMenuOption(id: string) {
      this.$emit("onShowMenuOption", id);
    },
    download(url: string) {
      let downloadPoint = document.createElement("a");
      downloadPoint.href = url;
      downloadPoint.target = "_blank";
      downloadPoint.click();
    },
    handleDownloadFile(contract: any) {
      if (contract.isFile) {
        contract.filePath
          ? this.download(getFilePathFromUrl(contract.filePath))
          : alert("Downloaded file is not exist");
      } else {
        contract.zipFilePath
          ? this.download(getFilePathFromUrl(contract.zipFilePath))
          : alert("Downloaded file is not exist");
      }
    },
    goToContract(contract: any): void {
      if (contract.status == ContractStatus.SIGNED)
        this.$router.push(`/sign/done?contractId=${contract.id}`);
      else this.$router.push(`/sign/details?contractId=${contract.id}`);
    },
    clickTitle(contract: any) {
      if (contract.isFile) {
        this.$emit('onViewDetailFile', contract);
      } else {
        this.$emit('onViewDetail', contract);
      }
    },
  },
});


import { defineComponent, reactive, toRefs } from "vue";
import FlatButton from "@/components/atomics/FlatButton.vue";

import { useStore } from "vuex";
import closeIcon from "@/assets/icons/close.svg";
import { RepositoryFactory, NotificationRepository } from "@/lib/https";
import Popup from "@/components/popups/Popup.vue";

export default defineComponent({
  name: "DeleteFolderNotificationPopup",
  components: {
    Popup,
    FlatButton,
  },
  emits: ["onDeleteNotificationSuccess", "onHide"],
  props: {
    popupState: { type: Boolean, default: false },
    notificationId: { type: String, required: true },
    title: { type: String, required: true },
    closeButton: { type: Boolean, default: true },
  },
  setup(props, context) {
    const state = reactive({
      closeIcon: closeIcon,
    });
    const store = useStore();
    const { deleteContractNotification } =
      RepositoryFactory.getRepository<NotificationRepository>(
        NotificationRepository
      );
    const showErrorNotification = (text: string) => {
      store.commit("notification/showErrorNotification", text);
    };
    const showPopupErrorNotification = (text: string) => {
      store.commit("notification/showPopupErrorNotification", text);
    };
    const confirmDelete = async function () {
      try {
        const result = await deleteContractNotification(props.notificationId);
        if (result) {
          hidePopup();
          context.emit("onDeleteNotificationSuccess");
        }
      } catch (err: any) {
        if (err.data) showPopupErrorNotification(err.data.message);
        hidePopup();
      }
    };
    const hidePopup = () => {
      context.emit("onHide");
    };

    return { ...toRefs(state), confirmDelete, hidePopup };
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e66b6824"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-popup" }
const _hoisted_2 = { class: "input-label" }
const _hoisted_3 = { class: "ml-1 primary-text" }
const _hoisted_4 = { class: "mt-2 user-add-input" }
const _hoisted_5 = { class: "error-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_ToggleCheckbox = _resolveComponent("ToggleCheckbox")!
  const _component_TableSelect = _resolveComponent("TableSelect")!
  const _component_MenuOptionDropdown = _resolveComponent("MenuOptionDropdown")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.popupState)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.title,
        onOnClickOutsidePopup: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isLoading ? null : _ctx.hidePopup())),
        onOnHide: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isLoading ? null : _ctx.hidePopup()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "popup-container",
            style: _normalizeStyle({ 'padding-bottom': _ctx.isOverflow ? '20px' : '4px' }),
            ref: "popup"
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("folder.addPopupTitleLabel")), 1),
              _createVNode(_component_ValidationTextField, {
                value: _ctx.editName,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editName) = $event)),
                placeholder: _ctx.$t('folder.addPopupTitleLabel'),
                rules: "required",
                name: "editName",
                message: {
            error_required: _ctx.$t('errors.required'),
          }
              }, null, 8, ["value", "placeholder", "message"]),
              _createElementVNode("span", {
                class: _normalizeClass(["row", _ctx.isMobile ? 'mt-3' : 'mt-5'])
              }, [
                _createVNode(_component_ToggleCheckbox, {
                  onOnChange: _ctx.showTableSelect,
                  checked: _ctx.isShowTableSelect
                }, null, 8, ["onOnChange", "checked"]),
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.isShowTableSelect
                ? _ctx.$t("folder.allowSpecificAccessOn")
                : _ctx.$t("folder.allowSpecificAccessOff")), 1)
              ], 2),
              _withDirectives(_createElementVNode("div", _hoisted_4, [
                _createVNode(_component_TableSelect, {
                  permissions: _ctx.permissions,
                  "search-hint": _ctx.$t('folder.folderSearchHint'),
                  items: _ctx.users.concat(_ctx.groups),
                  "item-types": "GroupUser",
                  "default-dropdrown-option": _ctx.selectedPermission,
                  onOnInput: _ctx.onSearchChange,
                  onOnUpdateLayout: _ctx.onUpdateLayoutTableSelect,
                  onOnUpdate: _cache[1] || (_cache[1] = 
              (value) => {
                _ctx.handleSelectUserGroup(value);
              }
            ),
                  onOnChangePermission: _cache[2] || (_cache[2] = (id) => _ctx.handleChangePermission(id))
                }, null, 8, ["permissions", "search-hint", "items", "default-dropdrown-option", "onOnInput", "onOnUpdateLayout"]),
                (_ctx.emptyError)
                  ? (_openBlock(), _createBlock(_component_MenuOptionDropdown, {
                      key: 0,
                      class: "error-tooltip"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("templateSigner.emptySelectedUser")), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 512), [
                [_vShow, _ctx.isShowTableSelect]
              ])
            ])
          ], 4),
          _createElementVNode("div", {
            class: _normalizeClass(["w-full section-footer", { 'border-top': _ctx.isOverflow }])
          }, [
            _createVNode(_component_flat_button, {
              class: "confirm-btn",
              text: _ctx.$t('common.createTo'),
              onOnLoading: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isLoading = $event)),
              "on-click": _ctx.createNewFolderBtn
            }, null, 8, ["text", "on-click"])
          ], 2)
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}
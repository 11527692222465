import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77439c67"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = { class: "text-popup" }
const _hoisted_3 = { class: "input-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_text_field = _resolveComponent("validation-text-field")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.popupState)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.renameSelectedPopup === _ctx.RenamePopupList.RENAME_FOLDER ? _ctx.$t('folder.renamePopupTitle') : _ctx.$t('folder.renameFile'),
        onOnClickOutsidePopup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isLoading ? null : _ctx.hidePopup())),
        onOnHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isLoading ? null : _ctx.hidePopup()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.renameSelectedPopup === _ctx.RenamePopupList.RENAME_FOLDER ? _ctx.$t('folder.addPopupTitleLabel') : _ctx.$t('folder.renameFileLabel')), 1),
              _createVNode(_component_validation_text_field, {
                value: _ctx.name,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                rules: "required",
                name: "name",
                message: {
            error_required: _ctx.$t('errors.required'),
          }
              }, null, 8, ["value", "message"]),
              _createVNode(_component_flat_button, {
                class: "confirm-btn",
                text: _ctx.$t('folder.changeBtn'),
                "on-click": _ctx.submitEdit,
                onOnLoading: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isLoading = $event))
              }, null, 8, ["text", "on-click"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}
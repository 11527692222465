import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-603763be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = { class: "popup-body" }
const _hoisted_3 = { class: "message" }
const _hoisted_4 = { class: "row-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.popupState)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.title,
        onOnClickOutsidePopup: _ctx.hidePopup,
        onOnHide: _ctx.hidePopup
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("folder.deleteNotificationMessage")), 1),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_flat_button, {
                  onOnClick: _ctx.confirmDelete,
                  text: _ctx.$t('group.buttonPopup'),
                  class: "custom-button"
                }, null, 8, ["onOnClick", "text"])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}